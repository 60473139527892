import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from '../Boading.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/images/logo.svg';
import InterviewCandidateListAPI from '../../api/InterviewCandidateList';
import SaveInterviewCandidateImage from '../../api/SaveInterviewCandidateImage';
import avtar from '../../assets/images/avtar.jpg';
import Style from '../../applicantContainers/Candidate/MainContent/Main.module.scss';
import AdminAuth from '../../api/AdminAuth';


const InterviewCandidateList = () => {


    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [imageSizeError, setImageSizeError] = useState('');
    const [imagLoading, setImagLoading] = useState({});
    const [currentDate, setCurrentDate] = useState('');
    const [currentDay, setCurrentDay] = useState('');
    const [authorized, setAuthorized] = useState(false);
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    

    

    const fetchData = async (token) => {
        const params = {
            show_all: false,
            date: currentDate 
        };
        try {
            setLoading(true);
            const response = await InterviewCandidateListAPI(token,params);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching candidate data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        const token = url.searchParams.get('token') || url.href.split('?')[1] || null;

        if (!token) {
            setShowUnauthorizedModal(true);
            setLoading(false);
            return;
        }

        const date = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        setCurrentDate(date.toLocaleDateString(undefined, options));
        const dayOfWeekOptions = { weekday: 'long' };
        const formattedDay = date.toLocaleDateString(undefined, dayOfWeekOptions);
        setCurrentDay(formattedDay);
        
        fetchData(token);
    }, []);



    const handleImageChange = async (e, candidateId) => {
        const url = new URL(window.location.href);
        const token = url.searchParams.get('token') || url.href.split('?')[1] || null;
        const file = e.target.files[0];

        if (file) {
            if (file.size > 30 * 1024 * 1024) {
                setImageSizeError('Image size exceeds 30MB limit.');
                return;
            }

            const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                setImageSizeError('Invalid file type. Only PNG and JPEG are allowed.');
                return;
            }

            setImageSizeError('');
            const formData = new FormData();
            formData.append('Image', file);

            // Set loading state for the specific candidate
            setImagLoading((prev) => ({ ...prev, [candidateId]: true }));

            try {
                const response = await SaveInterviewCandidateImage(formData, candidateId,token);
                toast.success(response?.msg || 'Image uploaded successfully!');
                fetchData();
            } catch (error) {
                console.error('Error saving candidate image:', error);
                toast.error('Error uploading image. Please try again.');
            } finally {
                // Reset loading state for the specific candidate
                setImagLoading((prev) => ({ ...prev, [candidateId]: false }));
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Candidates</title>
            </Helmet>
            <section className={`${styles['box-shadow']} ${styles.container}`}>
                <div className={styles.header}>
                    <div className={styles.grid}>
                        <div className={styles.col12}>
                            <ul>
                                <li className={styles['li-first']}>
                                    <div className={styles.logo}>
                                        <img src={Logo} alt="Logo" width="195" height="42" />
                                    </div>
                                </li>
                                <li className={styles['li-third']}>
                                    <div className={`${styles['align-left']} ${styles.candiadteHeading}`}>
                                        Candidates
                                    </div>
                                </li>
                                <li className={styles['li-lastDate']}>
                                    <div className={styles['align-right']} style={{ color: '#2400a1', fontWeight: '500', fontSize: '1.5rem' }}>
                                        {currentDay}, {currentDate}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={styles.employee}>
                    <div className={styles.grid}>
                        {loading ? (
                            <div>Loading...</div>
                        ) : (
                            data.map((candidate) => (
                                <div key={candidate.candidate_id} className={`${styles.col11} ${styles['margin-auto']}`} style={{ padding: '10px' }}>
                                    <div className={`${styles['box-shadow']} ${styles['border-radius']}`}>
                                        <div className={styles.InterviewLeft}>
                                            <div className={styles['avatar-intervire-preview']}>
                                                <div className={`${Style['div']} ${Style.width} ${Style['basicINFO']}`}>
                                                    <div className={Style['avatar-upload']}>
                                                        <div className={Style['avatar-edit']}>
                                                            <input
                                                                type='file'
                                                                id={`imageUpload-${candidate.candidate_id}`}
                                                                name="imageUpload"
                                                                accept="image/*"
                                                                capture="user"
                                                                onChange={(e) => handleImageChange(e, candidate.candidate_id)}
                                                            />
                                                            <label htmlFor={`imageUpload-${candidate.candidate_id}`}></label>
                                                        </div>
                                                        <div className={Style['avatar-preview']}>
                                                            {imagLoading[candidate.candidate_id] ? (
                                                                <div className={Style.loader}></div>
                                                            ) : (
                                                                <img
                                                                    id="imagePreview"
                                                                    src={candidate.candidate_profile_picture || avtar}
                                                                    alt={candidate.candidate_name || 'Default'}
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {imageSizeError && (
                                                        <span style={{ color: 'red', fontSize: '13px', paddingLeft: '10px' }}>{imageSizeError}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.InterviewRIght}>
                                            <fieldset className={styles.fieldset}>
                                                <ul className={styles.interviewDetails}>
                                                    <li><strong>Candidate Name:</strong> {candidate.candidate_name}</li>
                                                    <li><strong>Applied For:</strong> {candidate.applied_for}</li>
                                                    <li><strong>Department:</strong> {candidate.department}</li>
                                                    <li>
                                                        <strong>SPOC: </strong>
                                                        {candidate.recruiter ? (
                                                            candidate.recruiter
                                                        ) : (
                                                            'Not Assigned'
                                                        )}
                                                    </li>
                                                    <li><strong>Interview Day:</strong> {candidate.day_name}</li>
                                                    <li><strong>Interview Date:</strong> {candidate.interview_date}</li>
                                                    <li><strong>Interview Time:</strong> {candidate.interview_time}</li>
                                                    <li><strong>Card Number:</strong> {candidate.punch_card}</li>
                                                    <li><strong>Entry Time:</strong> {candidate.entry_time}</li>
                                                    <li><strong>Exit Time:</strong> {candidate.exit_time}</li>

                                                </ul>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>

                <ToastContainer />
                {showUnauthorizedModal && (
                    <div id="myModal" className={styles.modal}>
                    <div className={styles['modal-content']}>
                        <p>Yor are not authorized to access this page.</p>
                    </div>
                </div>
                )}
            </section>
        </>
    );
};

export default InterviewCandidateList;
