import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useToken } from '../../context/AuthContext';
import styles from './Admin.module.scss';
import Table from '../../components/Table/Table';
import View from '../../assets/images/view.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CandidateDetail from './CandidateDetail';
import FeedbackDetail from './FeedbackDetail';
import { Helmet } from 'react-helmet-async';
import EmployeeList from '../../api/EmployeeList';
import { Navigate } from "react-router-dom";
import Archive from '../../assets/images/archive.svg'
import Download from '../../assets/images/pdf.svg';
import Tab from '../../assets/images/tab_expand.svg';
import OnBoardingPdf from './OnBoardingPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
const tabNameMapping = {
    "OnClassification": "Classification",
    "OnIT": "IT",
    "OnAdministrative": "Administrative",
    "OnAccount": "Account",
    "OnEmployeeDetails": "Employee Details",
    "OnPerformance": "Performance",
    "OnFinalHR": "Final HR",
};
const EmployeeDetail = ({ deptname, activeTab, setActiveTab, authenticateToken, tabs }) => {
   
    const { token } = useToken();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [showCandidateDetail, setCandidateDetail] = useState(false);
    const [showFeedback, setFeedback] = useState(false);
    const [tokenValue, settoken] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [disableTabs, setDisableTabs] = useState([false, false, false]);
    const [employeeId, setEmployeeId] = useState(null);
    const [noData, setNoData] = useState(false);
    const [tabVisibility, setTabVisibility] = useState([true, true, true]);
    const tabTitles = [
        'Candidate',
        'Onboarding',
        'Offboarding',

    ];

    const managerID = localStorage.getItem('managerID');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentTabProgress, setCurrentTabProgress] = useState([]);
    const [isTabsPopupOpen, setIsTabPopupOpen] = useState(false);
    const [selectedTabs, setSelectedTabs] = useState({
        OnClassification: false,
        OnAdministrative: false,
        OnIT: false,
        OnEmployeeDetails: false,
        OnPerformance: false,
        OnFinalHR: false,
        OnAccount: false,
    });
    const activeTabRef = useRef(activeTab);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [deleteComment, setDeleteComment] = useState('');
    const fetchData = async () => {
        try {
            setLoading(true);
            const candidateData = await EmployeeList(authenticateToken);
            if (candidateData.data.length === 0) {
                setNoData(true);
            } else {
                setData(candidateData.data);
                const pages = Math.ceil(candidateData.data.length / pageSize);
                setTotalPages(pages);
                setNoData(false);
            }
        } catch (error) {
            console.error('Error fetching employee data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        fetchData();
    }, [authenticateToken, pageSize]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const activeTabsParam = parseInt(urlParams.get('activeTab'), 10) || 0;
        const tabIndices = {
            'Candidate': 0,
            'Onboarding': 1,
            'Offboarding': 2,
        };

        const newDisableTabs = Array(3).fill(true);
        const activeIndices = tabs.map(tab => tabIndices[tab]);

        activeIndices.forEach(index => {
            if (index !== undefined) {
                newDisableTabs[index] = false;
            }
        });

        const newTabVisibility = newDisableTabs.map(disabled => !disabled);

        let newActiveTab = 0;

        if (activeTabsParam !== undefined && !newDisableTabs[activeTabsParam]) {
            newActiveTab = activeTabsParam;
        } else {
            const firstVisibleTabIndex = newTabVisibility.findIndex(visible => visible);
            if (firstVisibleTabIndex !== -1) {
                newActiveTab = firstVisibleTabIndex;
            }
        }

        if (activeTabRef.current !== newActiveTab) {
            activeTabRef.current = newActiveTab;
            //console.log('fff'+ activeTabRef.current);
            //setActiveTab(activeTabRef.current);
        }

        setDisableTabs(newDisableTabs);
        setTabVisibility(newTabVisibility);
    }, [deptname, tabs, setActiveTab]);

    const handleSort = (column) => {
        const sortedData = [...data];
        const currentSort = sortState[column] || "asc";
        sortedData.sort((a, b) => currentSort === "asc" ? (a[column] > b[column] ? 1 : -1) : (a[column] < b[column] ? 1 : -1));
        setSortState({ ...sortState, [column]: currentSort === "asc" ? "desc" : "asc" });
        setData(sortedData);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filteredData = data.filter(item =>
            (item.full_name && item.full_name.toLowerCase().includes(query.toLowerCase())) ||
            (item.Job_Title && item.Job_Title.toLowerCase().includes(query.toLowerCase())) ||
            (item.Department && item.Department.toLowerCase().includes(query.toLowerCase())) ||
            (item.Joining_Date && item.Joining_Date.toLowerCase().includes(query.toLowerCase())) ||
            (item.Status && item.Status.toLowerCase().includes(query.toLowerCase()))
        );
        setTotalPages(Math.ceil(filteredData.length / pageSize));
    };

    const filteredData = data.filter(item =>
        (item.full_name && item.full_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.Job_Title && item.Job_Title.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.Department && item.Department.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.Joining_Date && item.Joining_Date.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.Status && item.Status.toLowerCase().includes(searchQuery.toLowerCase()))
    );


    const handleChangePage = (page) => setCurrentPage(page);

    const handleClosePopup = () => {
        setCandidateDetail(false);
        setFeedback(false);
    };

    const sendEmployeeDetail = async (employeeId) => {
        setEmployeeId(employeeId);
        setRedirect(true);
    };

    const handleTabClick = (index) => {

        if (tabVisibility[index]) {
            setActiveTab(index);
        }
    };
    const handleArchivedClick = (userId) => {
        console.log(userId)
        setSelectedUser(userId);
        setIsPopupOpen(true);
    };
    const handleActiveTabsClick = (userId) => {
        setSelectedUser(userId);
        setIsTabPopupOpen(true)
    }
    const handleActiveTabPopupClose = () => {
        setIsTabPopupOpen(false)
    }

    const handleCommentChange = (event) => {
        setDeleteComment(event.target.value);
    };



    const handleArchiveSubmit = async (event) => {
        event.preventDefault();
        console.log(selectedUser)
        if (!deleteComment) {
            alert("Please enter a comment before archiving.");
            return;
        }

        setIsPopupOpen(false);
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/onboarding/candidate-archive`,
                {
                    user_id: selectedUser,
                    archive: true,
                    archive_comment: deleteComment,
                },
                {
                    headers: {
                        token: authenticateToken,
                        accept: 'application/json',
                    },
                }
            );

            if (response.data) {
                setLoading(false);
                setDeleteComment('')
                await fetchData();
                toast.success(response.data.msg, {
                    duration: 4000,
                });
            } else {
                toast.error('There is no token for selected candidate', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }
    };


    const handlActiveTabSubmit = async (event) => {
        event.preventDefault();
        console.log(selectedTabs);
        const activeTabs = Object.keys(selectedTabs)
            .filter(tab => selectedTabs[tab])
            .map(tab => `On${tabNameMapping[tab]?.replace(/\s+/g, '')}`);

        if (Object.keys(activeTabs).length === 0) {
            alert("Please select at least one tab.");
            return;
        }
        setIsTabPopupOpen(false)
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/onboarding/candidate-tab-reopen`,
                {
                    user_id: selectedUser,
                    tab_name: activeTabs,
                    main_tab: 'OnBoarding',
                },
                {
                    headers: {
                        token: authenticateToken,
                        accept: 'application/json',
                    },
                }
            );

            if (response.data) {
                setLoading(false);
                await fetchData();
                toast.success(response.data.msg, {
                    duration: 4000,
                });


                setSelectedTabs({
                    OnClassification: false,
                    OnAdministrative: false,
                    OnIT: false,
                    OnEmployeeDetails: false,
                    OnPerformance: false,
                    OnFinalHR: false,
                    OnAccount: false,
                });
            } else {
                toast.error('There is no token for selected candidate', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
        setDeleteComment('');
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setSelectedTabs((prevSelectedTabs) => {
            const newSelectedTabs = { ...prevSelectedTabs, [name]: checked };

            if (name === "OnClassification" && checked) {
                newSelectedTabs["OnIT"] = true;
            } else if (name === "OnClassification" && !checked) {
                newSelectedTabs["OnIT"] = false;
            }
            else if (name === "OnIT" && !checked && prevSelectedTabs["OnClassification"]) {
                newSelectedTabs["OnClassification"] = false;
            }
            return newSelectedTabs;
        });
    };

    const checkTabProgress = (progress) => {
        const stages = ['ED', 'CF', 'IT', 'AD', 'AC', 'PF', 'FHR'];

        return stages.map((stage, index) => {
            const status = progress[index] || 'Pending';
            const isCompleted = status === 'Submitted';

            return (
                <div className={styles['progress-stage']} key={index} style={{ display: 'inline-block', marginRight: '20px', textAlign: 'center' }}>
                    <div className={`${styles.circle} ${isCompleted ? styles.completed : ''}`}>

                        {stage}
                    </div>

                </div>
            );
        });
    };


    const columns = [
        { title: 'Full Name', dataKey: 'full_name', sortable: true, minwidth: 205 },
        { title: 'Job Title', dataKey: 'Job_Title', sortable: true, minwidth: 325 },
        { title: 'Department', dataKey: 'Department', sortable: true, minwidth: 325 },
        {
            title: 'Joining Date',
            dataKey: 'Joining_Date',
            sortable: true,
            minwidth: 150,
        },
        {
            title: 'Status',
            dataKey: 'Status',
            sortable: true,
            minwidth: 130,
        },
        {
            title: 'Action',
            dataKey: 'Zoho_Item_Id',
            renderer: (rowData) => (
                <div className={styles.toolTipContainer} key={rowData.Zoho_Item_Id}>
                    <button
                        className={styles['custom-tooltip']}
                        onClick={() => sendEmployeeDetail(rowData.Zoho_Item_Id)}>
                        <img src={View} alt="First Action" width="20" height="20" title="Onboarding Candidate Details"/>

                    </button>
                    {deptname === 'Human Resources' && (
                    <button
                        onClick={() => handleArchivedClick(rowData.Zoho_Item_Id)}
                        className={styles['custom-tooltip']}
                    >
                        <img src={Archive} alt="Archived User" width="20" height="20" title="Archive Candidate"/>
                    </button>
                    )}
                    
                    <PDFDownloadLink
                        key={rowData.Zoho_Item_Id}
                        document={<OnBoardingPdf blitz_user_id={rowData.Zoho_Item_Id} token={authenticateToken} />}
                        fileName="onboarding-candidate-detail.pdf"
                        style={{ textDecoration: 'none' }}
                        onStart={() => setLoading(true)}
                        onError={() => setLoading(false)}
                        onLoad={() => setLoading(false)}
                    >
                        {({ loading }) => (
                            <button
                                className={styles['custom-tooltip']}
                                disabled={loading || !rowData.pdf_available}
                            >
                                <img src={Download} alt="First Action" width="20" height="20" title="Download PDF"/>
                            </button>
                        )}
                    </PDFDownloadLink>
                    <button
        className={styles['custom-tooltip']}
        onClick={() => handleActiveTabsClick(rowData.Zoho_Item_Id)}>
        <img src={Tab} alt="First Action" width="20" height="20" title="Reopen Onboarding Tab"/>
        
    </button>
                     
                </div>
            ),
            minwidth: 170,
        },


    ];
    const disabledTabClass = styles.disabledTab;
    return (
        <>
            <Helmet>
                <title>Employee List</title>
            </Helmet>
            {showCandidateDetail ? (
                <CandidateDetail url={tokenValue} onClose={handleClosePopup} />
            ) : showFeedback ? (
                <FeedbackDetail url={tokenValue} onClose={handleClosePopup} />
            ) : (
                <>
                    {activeTab === 1 && (
                        <>
                            <div className={styles.grid}>
                                <div className={styles.col8}>
                                    <ul className={styles.tab}>
                                        {tabTitles.map((title, index) => (
                                            <li key={index}>
                                                <a
                                                    href="#"
                                                    className={`${index === activeTab ? styles.current : ''} ${disableTabs[index] ? disabledTabClass : ''}`}
                                                    onClick={() => handleTabClick(index)}
                                                    style={disableTabs[index] ? { pointerEvents: 'none', background: '#c5c6c7', color: 'gray', display:'none' } : {}}
                                                >
                                                    {title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={styles.col4}>
                                    <div className={`${styles['search-icon']} ${styles['search-space-top']}`}>
                                        <input type="search" onChange={(e) => handleSearch(e.target.value)} className={styles['search-field']} placeholder="Search …" value={searchQuery} name="s" title="Search for:" />
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.grid} ${styles['grid-height']}`}>
                                {redirect && (
                                    <Navigate to={`/onboarding?token=${token}&employeeId=${employeeId}`} replace={true} />
                                )}
                                <div className={styles['dashboard-table1']}>

                                    <Table
                                        data={filteredData}
                                        columns={columns}
                                        pageSize={pageSize}
                                        onSort={handleSort}
                                        loading={loading}
                                        pagination={pagination}
                                        onChangePage={handleChangePage}
                                        total={totalPages}
                                        activePage={currentPage}
                                        limit={totalPages}
                                        minWidth={20}
                                        rowHeight={60}
                                    />

                                </div>
                            </div>
                        </>

                    )}
                    <ToastContainer />
                </>
            )}
            {isPopupOpen && (
                <div className={styles.rejectpopup}>
                    <>
                        <div className={styles.rejectpopupContent} style={{ width: '400px' }}>
                            <button className={styles.closeIntervieButton} onClick={() => setIsPopupOpen(false)}>
                                &times;
                            </button>
                            <div className={styles.employee}>

                                <>
                                    <div className={styles.col9}>
                                        <fieldset className={styles.fieldset}>
                                            <form id={styles.basicINFO} name="basicINFO" onSubmit={handleArchiveSubmit}>
                                                <legend style={{ paddingBottom: '20px', fontSize: '18px' }}> Reason to archive selected user</legend>
                                                <div className={styles.grid}>


                                                    <div >
                                                        <ul>
                                                            <li>

                                                                <span>
                                                                    <textarea name="archive_comment" onChange={handleCommentChange} className={`${styles.input}`} value={deleteComment} placeholder="Comment" ></textarea>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* Button block */}
                                                    <div className={styles['button-block']}>



                                                        <input type="submit" value="Delete" className={styles.button} disabled={loading} />


                                                    </div>
                                                </div>

                                            </form>
                                        </fieldset>
                                    </div>
                                    {loading && (
                                        <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                                            <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                                                <div className={styles.newsloader}></div>
                                            </div>
                                        </div>
                                    )}
                                </>

                            </div>

                        </div>

                    </>
                </div>
            )}
            {isTabsPopupOpen && (
                <div className={styles.rejectpopup}>
                    <div className={styles.rejectpopupContent} style={{ width: '450px' }}>
                        <button className={styles.closeIntervieButton} onClick={handleActiveTabPopupClose}>
                            &times;
                        </button>
                        <div className={styles.employee}>
                            <div className={styles.col9}>
                                <fieldset className={styles.fieldset}>
                                    <form id={styles.basicINFO} name="basicINFO" onSubmit={handlActiveTabSubmit}>
                                        <legend style={{ paddingBottom: '20px', fontSize: '18px' }}>
                                        Select Following Options To Reopen Tab
                                        </legend>
                                        <div className={styles.grid}>

                                            {/* Checkbox list for tabs */}
                                            <div>
                                                <fieldset>
                                                    <ul className={styles.checkboxList}>
                                                        <div className={styles.checkboxColumn}>
                                                            {Object.keys(tabNameMapping).slice(0, 4).map((tab) => (
                                                                <li key={tab} className={styles.checkboxItem}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={tab}
                                                                        name={tab}
                                                                        checked={selectedTabs[tab]}
                                                                        onChange={handleCheckboxChange}
                                                                        className={styles.checkboxInput}
                                                                    />
                                                                    <label htmlFor={tab} className={styles.checkboxLabel}>
                                                                        {tabNameMapping[tab]}
                                                                    </label>
                                                                </li>
                                                            ))}
                                                        </div>
                                                        <div className={styles.checkboxColumn}>
                                                            {Object.keys(tabNameMapping).slice(4).map((tab) => (
                                                                <li key={tab} className={styles.checkboxItem}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={tab}
                                                                        name={tab}
                                                                        checked={selectedTabs[tab]}
                                                                        onChange={handleCheckboxChange}
                                                                        className={styles.checkboxInput}
                                                                    />
                                                                    <label htmlFor={tab} className={styles.checkboxLabel}>
                                                                        {tabNameMapping[tab]}
                                                                    </label>
                                                                </li>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </fieldset>
                                            </div>

                                            {/* Button block */}
                                            <div className={styles['button-block']}>
                                                <input
                                                    type="submit"
                                                    value="Reopen"
                                                    className={styles.button}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>

                            {/* Loading Spinner */}
                            {loading && (
                                <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                                        <div className={styles.newsloader}></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EmployeeDetail;
