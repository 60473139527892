import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from '../Boading.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import InterviewCandidateListAPI from '../../api/InterviewCandidateList';
import SaveInterviewCandidateImage from '../../api/SaveInterviewCandidateImage';
import avtar from '../../assets/images/avtar.jpg';
import Style from '../../applicantContainers/Candidate/MainContent/Main.module.scss';
import { DateRangePicker } from 'rsuite';
import 'react-datepicker/dist/react-datepicker.css';
import Back from '../../assets/images/icon-back-main.svg';

const HrInterviewCandidateList = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [imageSizeError, setImageSizeError] = useState('');
    const [imagLoading, setImagLoading] = useState({});
    const [dateRange, setDateRange] = useState([null, null]); // Set initial date range to empty
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const redirectToDashboard = () => {
        const searchParams = new URLSearchParams(window.location.search);
        let token = searchParams.get('token');
        navigate('/dashboard?token=' + token);
    };
    const navigate = useNavigate();
    const fetchData = async (token, params) => {
        try {
            setLoading(true);
            const response = await InterviewCandidateListAPI(token, params);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching candidate data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (!token) {
            setShowUnauthorizedModal(true);
            setLoading(false);
            return;
        }

        // Fetch all candidates initially
        fetchData(token, { show_all: true });
    }, []);

    const handleDateRangeChange = (value) => {
        if (value && Array.isArray(value) && value.length === 2) {
            // Date range selected
            setDateRange(value);
            const token = new URLSearchParams(window.location.search).get('token');
            const params = {
                show_all: true,
                start_date: value[0].toISOString().split('T')[0],
                end_date: value[1].toISOString().split('T')[0],
            };
            fetchData(token, params);
        } else {
            // Date range cleared
            setDateRange([null, null]);
            const token = new URLSearchParams(window.location.search).get('token');
            fetchData(token, { show_all: true });
        }
    };


    const handleImageChange = async (e, candidateId) => {
        const file = e.target.files[0];
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (file) {
            if (file.size > 30 * 1024 * 1024) {
                setImageSizeError('Image size exceeds 30MB limit.');
                return;
            }

            const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                setImageSizeError('Invalid file type. Only PNG and JPEG are allowed.');
                return;
            }

            setImageSizeError('');
            const formData = new FormData();
            formData.append('Image', file);
            setImagLoading((prev) => ({ ...prev, [candidateId]: true }));

            try {
                const response = await SaveInterviewCandidateImage(formData, candidateId, token);
                toast.success(response?.msg || 'Image uploaded successfully!');
                fetchData();
            } catch (error) {
                console.error('Error saving candidate image:', error);
                toast.error('Error uploading image. Please try again.');
            } finally {
                setImagLoading((prev) => ({ ...prev, [candidateId]: false }));
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Candidates</title>
            </Helmet>
            <section className={`${styles['box-shadow']} ${styles.container}`}>
                <div className={styles.header}>
                    <div className={styles.grid}>
                        <div className={styles.col12}>
                            <ul>
                                <li className={styles['li-first']}>
                                    <div className={styles.logo}>
                                        <img src={Logo} alt="Logo" width="195" height="42" />
                                    </div>
                                </li>
                                <li className={styles['li-third']}>
                                    <div className={`${styles['align-left']} ${styles.candiadteHeading}`}>
                                        Candidates
                                    </div>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>

                <div className={styles.col6} style={{ width: "80%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ paddingLeft:"90px"}}>
                        <button onClick={redirectToDashboard}>
                            <img src={Back} width="30" height="15" alt="Back" />
                        </button>
                    </div>

                    <div className={styles.datePickerContainer}>
                        <DateRangePicker
                            value={dateRange}
                            onChange={handleDateRangeChange}
                            format="yyyy-MM-dd"
                        />
                    </div>
                </div>


                <div className={styles.employee}>
                    <div className={styles.grid}>
                        {loading ? (
                            <div>Loading...</div>
                        ) : data.length === 0 ? (
                            <div className={`${styles['no-data']} ${styles['col11']} ${styles['margin-auto']}`} style={{ padding: '10px', textAlign: 'center' }}>
                                No data found
                            </div>
                        ) : (
                            data.map((candidate) => (
                                <div key={candidate.candidate_id} className={`${styles.col11} ${styles['margin-auto']}`} style={{ padding: '10px' }}>
                                    <div className={`${styles['box-shadow']} ${styles['border-radius']}`}>
                                        <div className={styles.InterviewLeft}>
                                            <div className={styles['avatar-intervire-preview']}>
                                                <div className={`${Style['div']} ${Style.width} ${Style['basicINFO']}`}>
                                                    <div className={Style['avatar-upload']}>
                                                        <div className={Style['avatar-preview']}>
                                                            {imagLoading[candidate.candidate_id] ? (
                                                                <div className={Style.loader}></div>
                                                            ) : (
                                                                <img
                                                                    id="imagePreview"
                                                                    src={candidate.candidate_profile_picture || avtar}
                                                                    alt={candidate.candidate_name || 'Default'}
                                                                    width="150"
                                                                    height="150"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {imageSizeError && (
                                                        <span style={{ color: 'red', fontSize: '13px', paddingLeft: '10px' }}>{imageSizeError}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.InterviewRIght}>
                                            <fieldset className={styles.fieldset}>
                                                <ul className={styles.interviewDetails}>
                                                    <li><strong>Candidate Name:</strong> {candidate.candidate_name}</li>
                                                    <li><strong>Applied For:</strong> {candidate.applied_for}</li>
                                                    <li><strong>Department:</strong> {candidate.department}</li>
                                                    <li><strong>SPOC: </strong>
                                                        {candidate.recruiter || 'Not Assigned'}
                                                    </li>
                                                    <li><strong>Interview Day:</strong> {candidate.day_name}</li>
                                                    <li><strong>Interview Date:</strong> {candidate.interview_date}</li>
                                                    <li><strong>Interview Time:</strong> {candidate.interview_time}</li>
                                                    <li><strong>Card Number:</strong> {candidate.punch_card}</li>
                                                    <li><strong>Entry Time:</strong> {candidate.entry_time}</li>
                                                    <li><strong>Exit Time:</strong> {candidate.exit_time}</li>
                                                </ul>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>


                <ToastContainer />
                {showUnauthorizedModal && (
                    <div id="myModal" className={styles.modal}>
                        <div className={styles['modal-content']}>
                            <p>You are not authorized to access this page.</p>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
};

export default HrInterviewCandidateList;
