import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo.png';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
const styles = StyleSheet.create({
    table: {
        border: 'none',
        paddingTop: '20',
    },
    tables: {
        border: 'none',
        paddingTop: '10',
    },
    row: {
        border: 'none',
    },
    tableOverCell: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5'
    },
    textElement: {
        whiteSpace: 'nowrap',
        paddingRight: '3',
        fontSize: '9'
    },
    tableCell: {
        fontSize: '9',
        padding: '3'
    },
    cell: {
        border: 'none',
        padding: 8,
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
    },
    text: {
        margin: 12,
        fontSize: 9,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
    },
    image: {
        width: 100,
        height: 50,
        marginRight: 10,
    },
    candidateimage: {
        width: 150,
        height: 150,
        marginRight: 10,
    },
    candidatetext: {
        width: 150,
        height: 150,
        marginRight: 10,
        fontSize: 9,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        fontSize: 12,
        color: 'grey',
    },
    basicTABLE: {
        border: 'none',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    mainHeading: {
        backgroundColor: '#263746',
        borderRadius: 5,
        color: '#fff',
        fontSize: 10,
        fontWeight: '500',
        marginBottom: 8,
        padding: 5,
        position: 'relative',
        textTransform: 'uppercase',
        marginTop: '20',
    },
    mainHeadings: {
        backgroundColor: '#8e8e9345',
        borderRadius: 5,
        color: '#0e103d',
        fontSize: 9,
        fontWeight: '700',
        marginBottom: 8,
        textTransform: 'uppercase',
        marginTop: '15',
        padding: 5,
    },
    basictd: {
        width: "33%",
        whiteSpace: 'normal',
        padding: '10px',
        verticalAlign: 'top',
    },
    span: {
        color: '#000',
        display: 'block',
        fontSize: 9,
        fontWeight: '600',
        textAlign: 'left',
        textTransform: 'uppercase',
        border: 'none',
    },
    div: {
        backgroundColor: '#f2f2f208',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        verticalAlign: 'top',
    },
    inputText: {
        background: '#fff',
        borderRadius: '6',
        boxSizing: 'border-box',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        padding: '5px',
        outline: 'none',
        textAlign: 'left',
        transition: '0.5s linear',
        width: '100%',
        paddingLeft: '20px',
    },
    inputTexts: {
        background: '#fff',
        borderRadius: '6',
        boxSizing: 'border-box',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        outline: 'none',
        textAlign: 'left',
        transition: '0.5s linear',
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
    inputTextfield: {
        background: '#fff',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        paddingLeft: 80,
    },
    colSpan: {
        width: '100%',
    },
    declare: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
        fontSize: 9,
    },
    checkbox: {
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        fontSize: 9,
    },
    checkmark: {
        width: 20,
        height: 20,
        border: '1px solid black',
        backgroundColor: '#f5f5f5',
        marginRight: 5,
        fontSize: 9,
    },
    checkmarkChecked: {
        backgroundColor: 'black',
    },
    red: {
        color: 'red',
    },
    tableHeader: {
        fontSize: '11',
        padding: '3',
        fontWeight: 'Bold'
    }
});

const OffBoardingPdf = ({ blitz_user_id, token, setInfoData }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [empJobResponsibility, setEmpJobResponsibility] = useState([]);
    const [accountDetails, setAccountDetails] = useState([]);
    const [classification, setClassification] = useState([]);
    const [performance, setPerformance] = useState([]);
    const [finalHrProcessnDetails, setFinalHrProcessnDetails] = useState([]);
    const [admin, setAdmin] = useState([]);
    const [it, setIt] = useState([]);
    const apiEndpoint = process.env.REACT_APP_API_URL + 'api/onboarding/view-onboarding-info';

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.post(
                    apiEndpoint,
                    { employee_id: blitz_user_id },
                    {
                        headers: {
                            'token': token,
                            'accept': 'application/json',
                        },
                    }
                );

                setEmployeeDetails(response.data.info.employee_details);
                setEmpJobResponsibility(response.data.info.emp_job_responsibility)
                setClassification(response.data.info.classification);
                setFinalHrProcessnDetails(response.data.info.final_hr_process);
                setAdmin(response.data.info.admin);
                setAccountDetails(response.data.info.account)
                setIt(response.data.info.it);
                setPerformance(response.data.info.performance)
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch data. Please check your internet connection or API status.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiEndpoint]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }
    const { remove_software_access, remove_mobile_access } = classification || {};


    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.header} fixed>
                    <Image style={styles.image} src={Logo} />
                    <Text style={styles.title}>Employee Onboarding Details</Text>
                </View>

                {/* Employee Details */}
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee Personal Details</Text>
                </View>


                <Table style={styles.table}>
                    <TR>

                        <TD style={styles.tableHeader}>Fields</TD>
                        <TD style={styles.tableHeader}>Details</TD>


                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>First Name</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.first_name || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Middle Name</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.middle_name || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Last Name</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.last_name || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Job Title</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.job_title || 'Business Development Executive'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Department</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.department || 'Business Development'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Date of Joining</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.joining_date || '2009-12-27'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Releaving Date</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.releaving_date || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Reason for Leaving</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.reason_for_leaving || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Personal Email</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.personal_email_address || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Street Address</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.street_address || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>City</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.city || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>State</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.state || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Country</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.country || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Job Location</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.job_location || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Hiring Manager</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.hiring_manager_name || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Hiring Manager Email</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.hiring_manager_email || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Shipment Location</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.shipment_location || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Ship Laptop</TD>
                        <TD style={styles.tableCell}>
                            {employeeDetails?.ship_laptop != null ? employeeDetails?.ship_laptop.toString() : 'true'}
                        </TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Archive</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.archive !== null ? employeeDetails?.archive.toString() : 'false'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Archive Comment</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.archive_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Apt Unit</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.apt_unit || '23'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Home Phone</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.home_phone || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Cell Phone</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.cell_phone || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Business Card Name Preferences</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.business_card_name_preferences || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '5px' }}>
                        <TD style={styles.tableCell}>Professional Email Name Preferences</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.professional_email_name_preferences || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Role Type</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.role_type || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Status</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.status || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created By</TD>
                        <TD style={styles.tableCell}>{employeeDetails?.created_by || 'N/A'}</TD>
                    </TR>
                </Table>


                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee Classification Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Fields</TD>
                        <TD style={styles.tableHeader}>Details</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project Handover</TD>
                        <TD style={styles.tableCell}>{classification?.project_handover || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Google Email</TD>
                        <TD style={styles.tableCell}>{classification?.google_email ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Inform Client</TD>
                        <TD style={styles.tableCell}>{classification?.inform_client ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Project Backup</TD>
                        <TD style={styles.tableCell}>{classification?.project_backup ? 'Yes' : 'No'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Remove Software Access</TD>
                        <TD style={styles.tableCell}>
                            {classification?.remove_software_access ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(classification.remove_software_access)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>No data available</Text>
                            )}
                        </TD>


                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Role Type</TD>
                        <TD style={styles.tableCell}>{classification?.role_type || 'Full Time'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Business Card Required</TD>
                        <TD style={styles.tableCell}>
                            {classification?.business_card_required != null ? classification?.business_card_required.toString() : 'true'}
                        </TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Training Period End</TD>
                        <TD style={styles.tableCell}>{classification?.training_period_end || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Title</TD>
                        <TD style={styles.tableCell}>{classification?.title || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>software_access</TD>
                        <TD style={styles.tableCell}>
                            {classification?.software_access ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(classification.software_access)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>No data available</Text>
                            )}
                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Mobile Access</TD>
                        <TD style={styles.tableCell}>
                            {classification?.mobile_access ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(classification.mobile_access)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>No data available</Text>
                            )}
                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>All Hands</TD>
                        <TD style={styles.tableCell}>{classification?.all_hands !== null ? classification?.all_hands.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Training Period Days</TD>
                        <TD style={styles.tableCell}>{classification?.training_period_days || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Start Date</TD>
                        <TD style={styles.tableCell}>{classification?.start_date || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Special Software Needs</TD>
                        <TD style={styles.tableCell}>{classification?.special_software_needs !== null ? classification?.special_software_needs.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>All Heads</TD>
                        <TD style={styles.tableCell}>{classification?.all_heads !== null ? classification?.all_heads.toString() : 'true'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Laptop Date</TD>
                        {classification?.laptop_date ? new Date(classification?.laptop_date).toISOString().split('T')[0] : 'N/A'}
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Special Software Name</TD>
                        <TD style={styles.tableCell}>
                            {classification?.special_software_name ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(classification.special_software_name)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>


                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>IT Form Rejected</TD>
                        <TD style={styles.tableCell}>{classification?.it_form_rejected !== null ? classification?.it_form_rejected.toString() : 'false'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Reporting Manager</TD>
                        <TD style={styles.tableCell}>{classification?.reporting_manager || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Additional Access</TD>
                        <TD style={styles.tableCell}>{classification?.additional_access !== null ? classification?.additional_access.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Strategy Meetings</TD>
                        <TD style={styles.tableCell}>{classification?.strategy_meetings !== null ? classification?.strategy_meetings.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{classification?.comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Employee ID</TD>
                        <TD style={styles.tableCell}>{classification?.employee_id || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Proposed Consulting Project</TD>
                        <TD style={styles.tableCell}>{classification?.proposed_consulting_project || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Additional Access Name</TD>


                        <TD style={styles.tableCell}>
                            {classification?.additional_access_name ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(classification.additional_access_name)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Operation</TD>
                        <TD style={styles.tableCell}>{classification?.operation !== null ? classification?.operation.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Days</TD>
                        <TD style={styles.tableCell}>{classification?.days || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>ID</TD>
                        <TD style={styles.tableCell}>{classification?.id || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Location</TD>
                        <TD style={styles.tableCell}>{classification?.location || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Network Calling</TD>
                        <TD style={styles.tableCell}>{classification?.network_calling !== null ? classification?.network_calling.toString() : 'true'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Employment Type</TD>
                        <TD style={styles.tableCell}>{classification?.employment_type || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>PC Equipment</TD>
                        <TD style={styles.tableCell}>
                            {classification?.pc_equipment ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(classification.pc_equipment)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>No data available</Text>
                            )}
                        </TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Country</TD>
                        <TD style={styles.tableCell}>{classification?.country || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Training Period Start</TD>
                        <TD style={styles.tableCell}>{classification?.training_period_start || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Status</TD>
                        <TD style={styles.tableCell}>{classification?.status || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created By</TD>
                        <TD style={styles.tableCell}>{classification?.created_by || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created Date</TD>
                        <TD style={styles.tableCell}> {classification?.created_date ? new Date(classification?.created_date).toISOString().split('T')[0] : 'N/A'}</TD>
                    </TR>

                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee IT Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Details</TD>

                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Additional Access Name</TD>

                        <TD style={styles.tableCell}>
                            {it?.additional_access_name ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(it.additional_access_name)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>PC Equipment</TD>
                        <TD style={styles.tableCell}>

                            {it?.pc_equipment && typeof it?.pc_equipment === 'object'
                                ? Object.entries(it.pc_equipment)
                                    .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                    .join(', ')
                                : 'No data available'}
                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Other Location</TD>
                        <TD style={styles.tableCell}>{it?.other_location || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Software Access</TD>


                        <TD style={styles.tableCell}>

                            {it?.software_access && typeof it?.software_access === 'object'
                                ? Object.entries(it.software_access)
                                    .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                    .join(', ')
                                : 'No data available'}
                        </TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Network Calling</TD>
                        <TD style={styles.tableCell}>{it?.network_calling !== null ? it?.network_calling.toString() : 'true'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Mobile Access</TD>
                        <TD style={styles.tableCell}>

                            {it?.mobile_access && typeof it?.mobile_access === 'object'
                                ? Object.entries(it.mobile_access)
                                    .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                    .join(', ')
                                : 'No data available'}
                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Security Code</TD>
                        <TD style={styles.tableCell}>{it?.security_code !== null ? it?.security_code.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Days</TD>
                        <TD style={styles.tableCell}>{it?.days || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Laptop Ready for Shipment</TD>
                        <TD style={styles.tableCell}>{it?.laptop_ready_for_shipment !== null ? it?.laptop_ready_for_shipment.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Security Information Sent</TD>
                        <TD style={styles.tableCell}>{it?.security_information_sent !== null ? it?.security_information_sent.toString() : 'true'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Special Software Needs</TD>
                        <TD style={styles.tableCell}>{it?.special_software_needs !== null ? it?.special_software_needs.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Country</TD>
                        <TD style={styles.tableCell}>{it?.country || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Special Software Name</TD>


                        <TD style={styles.tableCell}>
                            {it?.special_software_name ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(it.special_software_name)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Business Card Required</TD>
                        <TD style={styles.tableCell}>{it?.business_card_required !== null ? it?.business_card_required.toString() : 'false'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Additional Access</TD>
                        <TD style={styles.tableCell}>{it?.additional_access !== null ? it?.additional_access.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Status</TD>
                        <TD style={styles.tableCell}>{it?.status || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{it?.comment || 'test'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created By</TD>
                        <TD style={styles.tableCell}>{it?.created_by || 'priyankaa.trivedi'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created At</TD>
                        <TD style={styles.tableCell}> {it?.created_at ? new Date(it?.created_at).toISOString().split('T')[0] : 'N/A'}</TD>


                    </TR>


                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee Admin Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Details</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Employee ID</TD>
                        <TD style={styles.tableCell}>{admin?.employee_id || '15'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Office Space</TD>
                        <TD style={styles.tableCell}>{admin?.office_space !== null ? admin?.office_space.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Administration Email</TD>
                        <TD style={styles.tableCell}>{admin?.administration_email !== null ? admin?.administration_email.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Ship Laptop</TD>
                        <TD style={styles.tableCell}>{admin?.ship_laptop !== null ? admin?.ship_laptop.toString() : 'true'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Days</TD>
                        <TD style={styles.tableCell}>{admin?.days || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Parking Allocation</TD>
                        <TD style={styles.tableCell}>{admin?.parking_allocation !== null ? admin?.parking_allocation.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Business Card</TD>
                        <TD style={styles.tableCell}>{admin?.business_card !== null ? admin?.business_card.toString() : 'true'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Shipment Information</TD>
                        <TD style={styles.tableCell}>{admin?.shipment_information || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Status</TD>
                        <TD style={styles.tableCell}>{admin?.status || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{admin?.comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created At</TD>
                        <TD style={styles.tableCell}> {admin?.created_at ? new Date(admin?.created_at).toISOString().split('T')[0] : 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created By</TD>
                        <TD style={styles.tableCell}>{admin?.created_by || 'N/A'}</TD>
                    </TR>

                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Account Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Details</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Employee ID</TD>
                        <TD style={styles.tableCell}>{accountDetails?.employee_id || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>KYC Comment</TD>
                        <TD style={styles.tableCell}>{accountDetails?.kyc_comment || 'N/A'}</TD>
                    </TR>

                    <TD style={styles.tableCell}>
                        {accountDetails?.bank_details ? (
                            <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                {Object.entries(accountDetails.bank_details)
                                    .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                    .join(', ')}
                            </Text>
                        ) : (
                            <Text>'N/A'</Text>
                        )}
                    </TD>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Bank Details Comment</TD>
                        <TD style={styles.tableCell}>{accountDetails?.bank_details_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Tax Form</TD>
                        <TD style={styles.tableCell}>
                            {accountDetails?.tax_form ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(accountDetails.tax_form)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>


                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Tax Comment</TD>
                        <TD style={styles.tableCell}>{accountDetails?.tax_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>EPF Form</TD>

                        <TD style={styles.tableCell}>
                            {accountDetails?.epf_form ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(accountDetails.epf_form)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>EPF Comment</TD>
                        <TD style={styles.tableCell}>{accountDetails?.epf_comment || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Docs Clear Copy</TD>

                        <TD style={styles.tableCell}>
                            {accountDetails?.docs_clear_copy ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(accountDetails.docs_clear_copy)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Bank Details</TD>

                        <TD style={styles.tableCell}>
                            {accountDetails?.bank_details ? (
                                <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                    {Object.entries(accountDetails.bank_details)
                                        .map(([software, access]) => `${software}: ${access ? 'Yes' : 'No'}`)
                                        .join(', ')}
                                </Text>
                            ) : (
                                <Text>'N/A'</Text>
                            )}
                        </TD>

                    </TR>



                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Days</TD>
                        <TD style={styles.tableCell}>{accountDetails?.days || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{accountDetails?.comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created By</TD>
                        <TD style={styles.tableCell}>{accountDetails?.created_by || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Status</TD>
                        <TD style={styles.tableCell}>{accountDetails?.status || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created At</TD>
                        <TD style={styles.tableCell}> {accountDetails?.created_at ? new Date(accountDetails?.created_at).toISOString().split('T')[0] : 'N/A'}</TD>
                    </TR>


                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Employee HR FInal Details</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Details</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Employee ID</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.employee_id || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>IT</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.it !== null ? finalHrProcessnDetails?.it.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Performance</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.performance !== null ? finalHrProcessnDetails?.performance.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Agreement Executed</TD>
                        <TD style={styles.tableCell}>
                            {finalHrProcessnDetails?.agreement_executed
                                ? Object.entries(finalHrProcessnDetails?.agreement_executed)
                                    .map(([key, value]) => {

                                        const displayValue = typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
                                        return `${key}: ${displayValue}`;
                                    })
                                    .join(', ')
                                : 'N/A'}
                        </TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>IT Comment</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.it_comment || 'test'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Performance Comment</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.performance_comment || 'test'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>All Documents Collected</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.all_documents_collected !== null ? finalHrProcessnDetails?.all_documents_collected.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Sales Marketing</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.sales_marketing || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Training Status</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.training_status || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Sales Marketing Comment</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.sales_marketing_comment || 'N/A'}</TD>
                    </TR>

                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>All Hands</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.all_hands !== null ? finalHrProcessnDetails?.all_hands.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Administrative</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.administrative !== null ? finalHrProcessnDetails?.administrative.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.comment || 'test'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>All Heads</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.all_heads !== null ? finalHrProcessnDetails?.all_heads.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Administrative Comment</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.administrative_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Days</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.days || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Welcome Email Sent</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.welcome_email_sent !== null ? finalHrProcessnDetails?.welcome_email_sent.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Strategy Meetings</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.strategy_meetings !== null ? finalHrProcessnDetails?.strategy_meetings.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Payroll</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.payroll !== null ? finalHrProcessnDetails?.payroll.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Payroll Comment</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.payroll_comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Background Check</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.background_check !== null ? finalHrProcessnDetails?.background_check.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Operation</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.operation !== null ? finalHrProcessnDetails?.operation.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Status</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.status || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created At</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.created_at ? new Date(finalHrProcessnDetails?.created_at).toISOString().split('T')[0] : 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created By</TD>
                        <TD style={styles.tableCell}>{finalHrProcessnDetails?.created_by || 'N/A'}</TD>
                    </TR>

                </Table>
                <View style={styles.mainHeading}>
                    <Text style={styles['accordion-btn']}>Performance</Text>
                </View>
                <Table style={styles.table}>
                    <TR>
                        <TD style={styles.tableHeader}>Field</TD>
                        <TD style={styles.tableHeader}>Status</TD>

                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Buddy Email</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.buddy_email || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Buddy 2 Email</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.buddy_email2 || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Induction</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.induction !== null ? performance?.[0]?.induction.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Basic Training</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.basic_training !== null ? performance?.[0]?.basic_training.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Buddy Assigned</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.buddy_assigned !== null ? performance?.[0]?.buddy_assigned.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Buddy Introductory Email Sent</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.buddy_introductory_email_sent !== null ? performance?.[0]?.buddy_introductory_email_sent.toString() : 'true'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Meeting Schedule</TD>

                        <TD style={styles.tableCell}> {performance?.[0]?.meeting_schedule ? new Date(performance?.[0]?.meeting_schedule).toISOString().split('T')[0] : 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Status</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.status || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Comment</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.comment || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created By</TD>
                        <TD style={styles.tableCell}>{performance?.[0]?.created_by || 'N/A'}</TD>
                    </TR>
                    <TR style={{ padding: '3px' }}>
                        <TD style={styles.tableCell}>Created At</TD>
                        <TD style={styles.tableCell}> {performance?.[0]?.created_at ? new Date(performance?.[0]?.created_at).toISOString().split('T')[0] : 'N/A'}</TD>

                    </TR>


                </Table>

            </Page>
        </Document>
    );
};

export default OffBoardingPdf;
