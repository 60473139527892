import axios from 'axios';

const AdminAuth = async (token,id,type) => {
 
  try {

    const body = {
      on_emp_id: type === 'onboarding' ? id : '',  
      off_emp_id: type === 'offboarding' ? id : '', 
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + 'api/employment-form/talent-blitz-authentication',
     
      body,
      {
        headers: {
          'token': token,
          'accept': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching candidate details:', error);
    if (error.response) {
      console.log('Response data:', error.response.data);
    }
    throw error;
  }
};

export default AdminAuth;
