import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeDetails from '../EmployeeDetails/EmployeeDetails';
import Classification from '../Classification/Classification';
import IT from '../IT/IT';
import Administrative from '../Administrative/Administrative';
import Payroll from '../Payroll/Payroll';
import FinalProcess from '../FinalHRProcess/FinalHRProcess';
import Performance from '../Performance/Performance';
import OnboardingTabProgress from '../../api/OnboradingTabProgress';



const tabTitles = [
  'Employee Details',
  'Classification',
  'IT',
  'Administrative',
  'Account',
  'Performance',
  'Final HR Process',
];

const Tabs = ({ department, authorized, token, employeeId, setActiveTab, admin, tabs, buttonTabs }) => {
  const [activeTab, setActiveTabLocal] = useState(0);
  const [disableTabs, setDisableTabs] = useState(Array(tabTitles.length).fill(false));
  
  const [TabStatus, setTabStatus] = useState({});
  const [ButtonStatus, setButtonStatus] = useState({});

  console.log(buttonTabs)



  useEffect(() => {
    const fetchTabProgress = async () => {
      if (token && employeeId) {
        try {
          const tabProgress = await OnboardingTabProgress(token, employeeId);
          
          setTabStatus(tabProgress.tab_progress[0]);
           
        } catch (error) {
          console.error('Error fetching tab progress:', error);
        }
      }
    };

    fetchTabProgress();
  }, [token, employeeId]);

  useEffect(() => {
    const tabIndices = {
      'Employee Details': 0,
      'Classification': 1,
      'IT': 2,
      'Administrative': 3,
      'Account': 4,
      'Performance': 5,
      'Final HR Process': 6,
    };

    const newDisableTabs = Array(tabTitles.length).fill(true);
    const activeIndices = tabs.map(tab => tabIndices[tab]);

    activeIndices.forEach(index => {
      if (index !== undefined) {
        newDisableTabs[index] = false;
      }
    });

    if (activeIndices.length > 0) {
      setActiveTabLocal(activeIndices[0]);
    }

    setDisableTabs(newDisableTabs);
  }, [department, tabs]);

  const handleTabClick = (index) => {
    if (!disableTabs[index]) {
      setActiveTab(index);
      setActiveTabLocal(index);
    }
  };

  const handleTabSubmission = (index) => {
    const updatedDisabledTabs = [...disableTabs];
    updatedDisabledTabs[index] = true;
    setDisableTabs(updatedDisabledTabs);
  };

  /*useEffect(() => {
    const normalize = (str) => str.trim().toLowerCase();

    
    const initialStatus = tabTitles.reduce((status, title) => {
      status[title] = false; 
      return status;
    }, {});

    
    buttonTabs.forEach((buttonTab) => {
      const normalizedButtonTab = normalize(buttonTab);
      tabTitles.forEach((title) => {
        const normalizedTitle = normalize(title);

       
        if (normalizedButtonTab === normalizedTitle) {
          initialStatus[title] = true;
        }
      });
    });

    
    setButtonStatus(initialStatus);

  }, [buttonTabs]);  */

  
  useEffect(() => {
    console.log('Updated ButtonStatus:', ButtonStatus);
  }, [ButtonStatus]);
  return (
    <div className={`${styles.grid} ${styles.relative} ${styles['grid-height']}`}>
      <div className={styles.col12}>
        <ul className={styles.tab}>
          {tabTitles.map((title, index) => (
           <li
           key={index}
           
         >
              <a
                href="#"
                className={`
                  ${index === activeTab ? styles.current : ''} 
                  ${disableTabs[index] ? styles.disabledTab : ''} 
                  ${TabStatus[title.replace(/\s+/g, '')] !== "Pending" ? styles.activeTabUnderline : ''}
                `}
                
                onClick={() => handleTabClick(index)}
                style={disableTabs[index] ? { pointerEvents: 'none', background: '#c5c6c7', color: 'gray' } : {}}
              >
                
                {title}
                {TabStatus[title.replace(/\s+/g, '')] !== "Pending" && (
                  <span className={styles.checkmark}></span>
                )}
                
              </a>
            </li>
          ))}
        </ul>
        {activeTab === 0 && <EmployeeDetails tabName={tabTitles[activeTab]} isActive={true} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[0]} onSubmit={() => handleTabSubmission(0)} activeTabIndex={activeTab} department={department} buttonTabs={buttonTabs} />}
        {activeTab === 1 && <Classification tabName={tabTitles[activeTab]}  authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[1]} onSubmit={() => handleTabSubmission(1)} activeTabIndex={activeTab} department={department} buttonTabs={buttonTabs}/>}
        {activeTab === 2 && <IT tabName={tabTitles[activeTab]} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[2]} onSubmit={() => handleTabSubmission(2)} activeTabIndex={activeTab} department={department} buttonTabs={buttonTabs}/>}
        {activeTab === 3 && <Administrative tabName={tabTitles[activeTab]} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[3]} onSubmit={() => handleTabSubmission(3)} activeTabIndex={activeTab} department={department} buttonTabs={buttonTabs}/>}
        {activeTab === 4 && <Payroll tabName={tabTitles[activeTab]} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[4]} onSubmit={() => handleTabSubmission(4)} activeTabIndex={activeTab} department={department} buttonTabs={buttonTabs}/>}
        {activeTab === 5 && <Performance tabName={tabTitles[activeTab]} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[5]} onSubmit={() => handleTabSubmission(5)} activeTabIndex={activeTab} department={department} buttonTabs={buttonTabs}/>}
        {activeTab === 6 && <FinalProcess tabName={tabTitles[activeTab]} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[6]} onSubmit={() => handleTabSubmission(6)} activeTabIndex={activeTab} department={department} buttonTabs={buttonTabs}/>}
      </div>
    </div>
  );
};

export default Tabs;
