import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../Boading.module.scss';
import Back from '../../assets/images/icon-back-main.svg';
import OnboadingEmployeeDetails from '../../api/FetchEmployeeDetail';
import AdminDashboard from '../../admin/CandidateList/AdminDashboard';
import { FaRegClock } from 'react-icons/fa';
const EmployeeInfo = ({ token, employeeId, refreshEmployeeInfo, createdBy,tab,logHistory,handleLogClick }) => {
  
  const [jobTitle, setjobTitle] = useState('');
  const [leftData, setLeftData] = useState({
    first_name: '',
    last_name: '',
    job_title: '',
    department: '',
    joining_date: '',
    role_type: '',
    job_location: ''
  });
  const [showCandidateDetail, setShowCandidateDetail] = useState(false);
  const navigate = useNavigate();

  const handleCandidateClick = () => {
    setShowCandidateDetail(true);
  };
  const extractDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); 
  };

  useEffect(() => {
    const url = 'get-employee-details';
    const fetchData = async () => {
      try {
        const employeeData = await OnboadingEmployeeDetails(url, token, employeeId);
        if (employeeData.status === 200) {
          const empDetails = employeeData.employee;
          setjobTitle(empDetails.job_title);
          setLeftData({
            first_name: empDetails.first_name || '',
            last_name: empDetails.last_name || '',
            job_title: empDetails.job_title || '',
            department: empDetails.department || '',
            joining_date: empDetails.joining_date || '',
            role_type: empDetails.role_type || '',
            job_location: empDetails.job_location || '',
          });
        } else {
          console.error('Error fetching employee data:', employeeData.error);
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchData();

  }, [token, employeeId, refreshEmployeeInfo]);
  localStorage.setItem('employeeJob', jobTitle);
  const redirectToDashboard = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let token = searchParams.get('token');
    navigate('/dashboard?token=' + token + '&activeTab=1');
  };
  const formattedName = createdBy ? createdBy.replace('.', ' ') : '';
  return (
    <div className={styles.col3}>
      <div className={`${styles['box-shadow']} ${styles['border-radius']}`}>
        <h2><span>Employee Details</span></h2>
        <ul>
          <li><strong>First Name:</strong> <span>{leftData.first_name}</span></li>
          <li><strong>Last Name:</strong> <span>{leftData.last_name}</span></li>
          <li><strong>Title:</strong> <span>{leftData.job_title}</span></li>
          <li><strong>Department:</strong> <span>{leftData.department}</span></li>
          {leftData.role_type && <li><strong>Role:</strong> <span>{leftData.role_type}</span></li>}
          {leftData.job_location && <li><strong>Location:</strong> <span>{leftData.job_location}</span></li>}
          {leftData.joining_date && <li><strong>Joining Date:</strong> <span>{leftData.joining_date}</span></li>}
          {formattedName && <li><strong>Classification Submitted By:</strong> <span>{formattedName}</span></li>}
        </ul>
 <div className={styles['align-right']}>
          <button onClick={redirectToDashboard}>
            <img src={Back} width="30" height="15" alt="Back" />
          </button>
        </div>
        
       
      </div>
       {/* Log History */}
        {tab === 'IT' && logHistory.length>0 &&(
          <div className={`${styles['box-shadow']} ${styles['border-radius']}`} style={{ marginTop: '20px' }}>
            <h2><span>Log History</span></h2>
            <ul>
              {logHistory.map((log, index) => (
                <li key={index} onClick={() => handleLogClick(log)} style={{ cursor: 'pointer', color: '#181c18c7', display: 'flex', alignItems: 'center' }}>
                  <FaRegClock style={{ marginRight: '8px', color: '#333' }} />
                  {log} 
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export default EmployeeInfo;
