import React, { useEffect, useState } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Payroll = ({ tabName, buttonTabs, department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [refreshEmployeeInfo, setRefreshEmployeeInfo] = useState(false);
    const [formData, setFormData] = useState({
        payroll_account_access: false,
        salary_account_opened: false,
        comment: '',
        bank_details_comment: '',
        bank_details: {
            cancelled_cheque: false,
            bank_account: false
        },
        kyc_comment: '',
        docs_clear_copy: {
            pan: false,
            aadhaar: false
        },
        epf_comment: '',
        epf_form: {
            form11: false,
            form2: false
        },
        tax_comment: '',
        tax_form: {
            form16: false,
            itr: false
        },
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (name === "pan" || name === "aadhaar") {
            setFormData(prevState => ({
                ...prevState,
                docs_clear_copy: {
                    ...prevState.docs_clear_copy,
                    [name]: checked
                }
            }));
        } else if (name === "cancelled_cheque" || name === "bank_account") {
            setFormData(prevState => ({
                ...prevState,
                bank_details: {
                    ...prevState.bank_details,
                    [name]: checked
                }
            }));
        } else if (name === "form11" || name === "form2") {
            setFormData(prevState => ({
                ...prevState,
                epf_form: {
                    ...prevState.epf_form,
                    [name]: checked
                }
            }));
        } else if (name === "form16" || name === "itr") {
            setFormData(prevState => ({
                ...prevState,
                tax_form: {
                    ...prevState.tax_form,
                    [name]: checked
                }
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            if (authorized) {
                try {
                    setLoading(true);
                    const url = 'get-employee-payroll';
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.payroll;
                        setFormData({
                            payroll_account_access: empDetails.payroll_account_access || false,
                            salary_account_opened: empDetails.salary_account_opened || false,
                            comment: empDetails.comment || '',
                            bank_details_comment: empDetails.bank_details_comment || '',
                            bank_details: {
                                cancelled_cheque: empDetails.bank_details?.cancelled_cheque || false,
                                bank_account: empDetails.bank_details?.bank_account || false
                            },
                            kyc_comment: empDetails.kyc_comment || '',
                            docs_clear_copy: {
                                pan: empDetails.docs_clear_copy?.pan || false,
                                aadhaar: empDetails.docs_clear_copy?.aadhaar || false
                            },
                            epf_comment: empDetails.epf_comment || '',
                            epf_form: {
                                form11: empDetails.epf_form?.form11 || false,
                                form2: empDetails.epf_form?.form2 || false
                            },
                            tax_comment: empDetails.tax_comment || '',
                            tax_form: {
                                form16: empDetails.tax_form?.form16 || false,
                                itr: empDetails.tax_form?.itr || false
                            },


                        });
                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }
                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [authorized, token, employeeId, refreshEmployeeInfo]);

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-payroll';
            const requestData = {
                employee_id: employeeId,
                payroll: {
                    ...formData,
                    status: "Pending",
                    created_by: admin,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success(response.msg);
            setRefreshEmployeeInfo(prevState => !prevState);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }
    const hanldeSubmit = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-payroll';
            const requestData = {
                employee_id: employeeId,
                payroll: {
                    ...formData,
                    status: "Submitted",
                    created_by: admin,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success(response.msg);
            setButtonsDisabled(true);
            setRefreshEmployeeInfo(prevState => !prevState);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {

            setLoading(false);
        }
    }
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: files[0] // Store the first file
        }));
    };
    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}></div>

                <EmployeeInfo token={token} employeeId={employeeId} refreshEmployeeInfo={refreshEmployeeInfo} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']}`}>
                        <fieldset className={`${styles.fieldset} ${buttonsDisabled ? styles['fieldset-disabled'] : ''}`}>
                            <legend style={{ paddingBottom: "20px" }}>Account Information</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            {/*} <li>
                                                <strong>Salary Account Opened​:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="salary_account_opened"
                                                        name="salary_account_opened"
                                                        checked={formData.salary_account_opened}
                                                        onChange={handleCheckboxChange}
                                                    /> <label htmlFor="salary_account_opened"></label>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Payroll Account Access</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="payroll_account_access"
                                                        name="payroll_account_access"
                                                        checked={formData.payroll_account_access}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="payroll_account_access"></label>
                                                </span>
                                            </li>*/}
                                            <li>
                                                <strong>Form Received if Employee Availing EPF:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="form11"
                                                        name="form11"
                                                        checked={formData.epf_form.form11}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="form11">Form 11</label>


                                                    <input
                                                        type="checkbox"
                                                        id="form2"
                                                        name="form2"
                                                        checked={formData.epf_form.form2}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="form2">Form 2</label>
                                                </span>

                                            </li>
                                            {(formData.epf_form.form11 || formData.epf_form.form2) && (

                                                <li>
                                                    <label>Comment</label>
                                                    <span style={{ paddingLeft: '10px' }}>
                                                        <textarea
                                                            name="epf_comment"
                                                            className={styles.input}
                                                            value={formData.epf_comment}
                                                            onChange={handleChange}
                                                        />
                                                    </span>
                                                </li>

                                            )}

                                            <li>

                                                <strong>Bank Details Received:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="cancelled_cheque"
                                                        name="cancelled_cheque"
                                                        checked={formData.bank_details.cancelled_cheque}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="cancelled_cheque">Cancelled Cheque</label>
                                                    <input
                                                        type="checkbox"
                                                        id="bank_account"
                                                        name="bank_account"
                                                        checked={formData.bank_details.bank_account}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="bank_account">Bank Account</label>
                                                </span>
                                            </li>
                                            {(formData.bank_details.cancelled_cheque || formData.bank_details.bank_account) && (

                                                <li>
                                                    <label> Comment:</label>
                                                    <span style={{ paddingLeft: '10px' }}>
                                                        <textarea
                                                            name="bank_details_comment"
                                                            className={styles.input}
                                                            value={formData.bank_details_comment}
                                                            onChange={handleChange}
                                                        />
                                                    </span>
                                                </li>

                                            )}

                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Employee KYC Received:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="pan"
                                                        name="pan"
                                                        checked={formData.docs_clear_copy.pan}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="pan">Pan</label>

                                                    <input
                                                        type="checkbox"
                                                        id="aadhaar"
                                                        name="aadhaar"
                                                        checked={formData.docs_clear_copy.aadhaar}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="aadhaar">Aadhaar</label>
                                                </span>
                                            </li>
                                            {(formData.docs_clear_copy.pan || formData.docs_clear_copy.aadhaar) && formData.kyc_comment && (
    <li>
        <label>Comment</label>
        <span style={{ paddingLeft: '10px' }}>
            <textarea
                name="kyc_comment"
                className={styles.input}
                value={formData.kyc_comment}
                onChange={handleChange}
            />
        </span>
    </li>
)}


                                            <li>
                                                <strong>For Prior employment, to ascertain tax details Received:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="form16"
                                                        name="form16"
                                                        checked={formData.tax_form.form16}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="form16">Form 16</label>
                                                    <input
                                                        type="checkbox"
                                                        id="itr"
                                                        name="itr"
                                                        checked={formData.tax_form.itr}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="itr">ITR</label>
                                                </span></li>
                                            {(formData.tax_form.form16 || formData.tax_form.itr) && (
                                                <li>
                                                    <label>Comment</label>
                                                    <span style={{ paddingLeft: '10px' }}>
                                                        <textarea
                                                            name="tax_comment"
                                                            className={styles.input}
                                                            value={formData.tax_comment}
                                                            onChange={handleChange}
                                                        />
                                                    </span>
                                                </li>

                                            )}



                                        </ul>
                                    </div>
                                </div>
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li>
                                            <strong>Comment<i className={styles['red']}>*</i>:</strong>
                                            <span>
                                                <textarea
                                                    name="comment"
                                                    className={styles.input}
                                                    value={formData.comment}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </span>
                                        </li>


                                    </ul>

                                </div>

                                {/* Button block */}
                                <div className={styles['button-block']}>
                                    {!buttonsDisabled && buttonTabs.includes(tabName) && (
                                        <input type="submit" value="Save" className={styles.button} onClick={hanldeSaveInfo} disabled={loading} />
                                    )}

                                    {!buttonsDisabled && buttonTabs.includes(tabName) && (
                                        <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} />
                                    )}

                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-payroll']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-payroll']} `}>
                        <div className={styles.newsloader}></div></div>
                </div>}
            </div>
        </div>
    );
};

export default Payroll;
