import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingClassificationEmployeeDetails from '../../api/SaveClassificationOnboading';
import getDepartmentList from '../../api/GetDepartmentList';
import DatePicker from "react-datepicker";
import SelectField from '../../components/SelectField/SelectField';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Classification = ({ tabName, department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex, buttonTabs }) => {

    const [loading, setLoading] = useState(true);
    const [start_date, setStartDate] = useState(null);
    const [laptopDate, setLaptopDate] = useState('');
    const [endDate, setEndDate] = useState(null);
    const [trainingStartDate, setTrainingStartDate] = useState('');
    const [trainingEndDate, setTrainingEndDate] = useState('');
    const [departments, setDepartments] = useState([]);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [trainingMaterial, settrainingMaterial] = useState('null');
    const [materialOption, setMaterialOption] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [error, setError] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [refreshEmployeeInfo, setRefreshEmployeeInfo] = useState(false);
    let empDetailsjob = localStorage.getItem('employeeJob');
    const animatedComponents = makeAnimated();
    const [departmentValue, setdepartmentValue] = useState('');
    const [rejectComment, setRejectComment] = useState('');
    const [selectedManager, setselectedManager] = useState(null);
    const [managerOptions, setManagerOptions] = useState([]);
    const [formData, setFormData] = useState({
        employment_type: '',
        title: empDetailsjob,
        start_date: null,
        laptop_date: null,
        department: '',
        role_type: '',
        proposed_consulting_project: '',
        location: 'India',
        locationRegion: '',
        special_software_needs: false,
        special_software_name: '',
        additional_access: false,
        additional_access_name: '',
        shipLaptopToAddress: '',
        country: '',
        //business_card_required: false,
        training_period_start: '',
        end_date_Of_training: '',
        trainingDay: '',
        network_calling: false,
        reporting_manager: '',
        all_hands: false,
        all_heads: false,
        strategy_meetings: false,
        training_tools_required: false,
        training_period_end: '',
        training_period_days: 0,
        operation: false,
        training_material: '',
        managers: [],


    });

    const [pcEquipment, setPCEquipment] = useState({});
    const [softwareAccess, setSoftwareAccess] = useState({});
    const [mobileAccess, setMobileAccess] = useState({});


    const locations = [
        { value: '-1', label: 'Select Location' },
        { value: '20, Okhla Phase III, New Delhi', label: '20, Okhla Phase III, New Delhi' },
        { value: 'B 21 Lajpat Nagar 2, New Delhi', label: 'B 21 Lajpat Nagar 2, New Delhi' },
        { value: 'Hyderabad', label: 'Hyderabad' },
        { value: 'Mumbai', label: 'Mumbai' },
        { value: 'Madurai', label: 'Madurai' },
        { value: 'WFH', label: 'Work From Home' },
    ];




    useEffect(() => {
        const url = 'get-employee-classification';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);

                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    let trainingDays = '';
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.classification;
                        if (empDetails.training_period_end === 'None' || empDetails.training_period_start === 'None') {
                            trainingDays = 0;
                        }
                        else {
                            trainingDays = empDetails.training_period_days || 0;
                        }
                        const managers = empDetails.managers;
                        const options = [
                            { value: '', label: 'Select Manager' },
                            ...managers.map(manager => ({ value: manager.trim(), label: manager }))
                        ];
                        setManagerOptions(options);
                        setFormData({
                            employment_type: empDetails.employment_type || '',
                            title: empDetailsjob,
                            department: empDetails.department || '',
                            role_type: empDetails.role_type || '',
                            proposed_consulting_project: empDetails.proposed_consulting_project || '',
                            location: empDetails.location || '',
                            locationRegion: empDetails.locationRegion || '',
                            //pcEquipment: empDetails.pcEquipment || '',
                            //softwareAccess: empDetails.softwareAccess || '',
                            //mobile_access: empDetails.mobile_access || '',
                            special_software_needs: empDetails.special_software_needs || false,
                            network_calling: empDetails.network_calling || false,
                            country: empDetails.country || '',
                            // business_card_required: empDetails.business_card_required || false,
                            strategy_meetings: empDetails.strategy_meetings || false,
                            start_date: empDetails.start_date || null,
                            laptop_date: empDetails.laptop_date || null,
                            training_period_days: trainingDays,
                            comment: empDetails.comment || '',
                            additional_access: empDetails.additional_access || false,
                            all_hands: empDetails.all_hands || false,
                            all_heads: empDetails.all_heads || false,
                            operation: empDetails.operation || false,
                            special_software_name: empDetails.special_software_name || '',
                            additional_access_name: empDetails.additional_access_name || '',
                            training_period_start: empDetails.training_period_start || '',
                            training_period_end: empDetails.training_period_end || '',
                            //reporting_manager: empDetails.reporting_manager || '',
                        });
                        const selectedOption1 = options.find(option => option.value === empDetails.reporting_manager);
                        setselectedManager(selectedOption1 || null);
                        const trainingMaterials = empDetails.training_material || [];
                        const selected = trainingMaterials.map(material => ({
                            label: material.training_name,
                            value: material.training_id,
                            trainID: material.training_id,
                        }));

                        if (empDetails.pc_equipment) {
                            setPCEquipment(empDetails.pc_equipment);
                        } else {
                            setPCEquipment({});
                        }
                        if (empDetails.software_access) {
                            setSoftwareAccess(empDetails.software_access);
                        } else {
                            setSoftwareAccess({});
                        }
                        if (empDetails.mobile_access) {
                            setMobileAccess(empDetails.mobile_access);
                        } else {
                            setMobileAccess({});
                        }
                        setRejectComment(empDetails.it_form_rejected_comment || '');


                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }

                        setLoading(false);

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId, refreshEmployeeInfo]);


    useEffect(() => {
        if (authorized) {

            const fetchDepartment = async () => {
                try {
                    const department = await getDepartmentList(token);
                    if (department.status === 200) {
                        setDepartments(department.department);

                    } else {
                        console.error('Error fetching employee data:');
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchDepartment();
        }
    }, [authorized, token]);

    useEffect(() => {
        if (authorized) {
            const getTrainingMaterial = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}api/onboarding/training-material-list`,
                        {
                            headers: {
                                'token': token,
                                'accept': 'application/json',
                            },
                        }
                    );
                    if (response.status === 200) {

                        const options = response.data.training_material.map(trainObj => {
                            const trainID = Object.keys(trainObj)[0];
                            const material = trainObj[trainID];
                            return { value: material, label: material, trainID };
                        });
                        setMaterialOption(options);

                    } else {
                        console.error('Error fetching training material data:');
                    }
                } catch (error) {
                    console.error('Error fetching training material:', error);
                }
            };

            // Call the function to get the training material
            getTrainingMaterial();
        }
    }, [authorized, token]);
    const handleDeselectChange = (deselectedOption) => {
        const updatedOptions = materialOption.filter(option => option !== deselectedOption);
        setMaterialOption(updatedOptions);
        setSelectedOption(updatedOptions);
    };
    const orderOptions = (values) => {
        return values
            .filter((v) => v.isFixed)
            .concat(values.filter((v) => !v.isFixed));
    };
    const handleSelectChange = (newValue, actionMeta) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = materialOption.filter((v) => v.isFixed);
                break;
        }
        setSelectedMaterials(orderOptions(newValue));

    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));

    };


    const handleAdditionalCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: checked,
            special_software_name: checked ? prevState.special_software_name : "",

        }));
    };

    const handleAccessCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: checked,
            additional_access_name: checked ? prevState.additional_access_name : "",
        }));
    };


    const handleCheckboxChangeIndia = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
            country: checked ? prevData.country : "",
        }));
    };

    const handlePCEquipmentChange = (event) => {
        const { name, checked } = event.target;
        setPCEquipment((prev) => ({
            ...prev,
            [name]: checked
        }));
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleStartDateChange = (date) => {
        setFormData({ ...formData, start_date: date });
    };

    const handleLaptopDateChange = (date) => {
        setFormData({ ...formData, laptop_date: date });
    }
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);

        if (isNaN(dateObject.getTime())) {
            console.error('Invalid dateObject:', dateObject);
            return '';
        }

        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function formattraingDate(date) {
        if (!date) return null;

        if (typeof date === 'string') {

            const parsedDate = new Date(date);
            const formattedDate = parsedDate.toISOString().split('T')[0];
            return formattedDate;
        } else if (date instanceof Date) {

            const formattedDate = date.toISOString().split('T')[0];
            return formattedDate;
        } else {
            return null;
        }
    }

    const handleTrainingStartDateChange = (date) => {
        const formattedDate = formattraingDate(date);
        if (formData.training_period_end && formattedDate > formData.training_period_end) {
            setButtonsDisabled(true);
            date = formData.training_period_end;
            setError("Start date should be before the end date.");
        } else {
            setButtonsDisabled(false);
            setError('');
        }

        setFormData(prevState => ({
            ...prevState,
            training_period_start: formattedDate
        }));

        if (formattedDate && formData.training_period_end) {
            const endDate = new Date(formattedDate);
            const startDate = new Date(formData.training_period_end);
            const millisecondsDifference = Math.abs(endDate - startDate);
            const daysDifference = Math.ceil(millisecondsDifference / (1000 * 60 * 60 * 24));
            const adjustedDaysDifference = daysDifference > 0 ? daysDifference + 1 : 1;
            setFormData(prevState => ({
                ...prevState,
                training_period_days: adjustedDaysDifference
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                training_period_days: 0
            }));
        }
    };



    const handleTrainingEndDateChange = (date) => {
        let formattedDate = formattraingDate(date);
        if (formData.training_period_start) {
            if (formattedDate < formData.training_period_start) {
                formattedDate = formData.training_period_start;
            }
        }

        setFormData(prevState => ({
            ...prevState,
            training_period_end: formattedDate
        }));

        if (formattedDate && formData.training_period_start) {
            const startDate = new Date(formData.training_period_start);
            const endDate = new Date(formattedDate);

            const millisecondsDifference = Math.abs(endDate - startDate);
            const daysDifference = Math.ceil(millisecondsDifference / (1000 * 60 * 60 * 24));
            const adjustedDaysDifference = daysDifference > 0 ? daysDifference + 1 : 1;

            setFormData(prevState => ({
                ...prevState,
                training_period_days: adjustedDaysDifference
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                training_period_days: 0
            }));
        }
    };






    const typeOptions = [
        { value: '-1', label: 'Employment Type' },
        { value: 'Full Time', label: 'Full Time' },
        { value: 'Consultant', label: 'Consultant' },
        { value: 'Contractual', label: 'Contractual' },
        { value: 'Freelancer', label: 'Freelancer' },
        { value: 'Intern', label: 'Intern' },


    ];

    const convertSoftwareNamesToObject = (softwareNames) => {
        const softwareObject = {};
        softwareNames.forEach(software => {
            const trimmedSoftware = software.trim();
            softwareObject[trimmedSoftware] = true;
        });
        return softwareObject;
    }


    const hanldeSaveInfo = async (event) => {
        //console.log(convertSoftwareNamesToObject(formData.special_software_name))
        event.preventDefault();
        if ((formData.training_period_start && !formData.training_period_end) ||
            (!formData.training_period_start && formData.training_period_end)) {
            toast.error('Please fill both start date and end date.');
            return;
        }
        if (formData.training_period_start && formData.training_period_end) {
            const startDate = formatDate(formData.training_period_start);
            const endDate = formatDate(formData.training_period_end);
            if (startDate > endDate) {
                toast.error('Start date should be before the end date.');
                return;
            }
        }
        if (formData.special_software_needs && !formData.special_software_name) {
            toast.error('Please provide special software needs.');
            return;
        }

        if (formData.additional_access && !formData.additional_access_name) {
            toast.error('Please provide additional access.');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }

        if (formData.network_calling && !formData.country) {
            toast.error('Please Provide Which Country!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-classification';
            let specialSoftwareName = formData.special_software_name;
            let additionalAccessName =formData.additional_access_name
            if (typeof specialSoftwareName === 'string') {

                let array = specialSoftwareName.split(",").map(item => item.trim());
                array = array.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
                //console.log(array); 

                specialSoftwareName = array;
            } else if (Array.isArray(specialSoftwareName)) {
                console.log(specialSoftwareName)
                specialSoftwareName = specialSoftwareName.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
            } else if (typeof specialSoftwareName === 'object') {

                specialSoftwareName = specialSoftwareName;
            }
            
            if (typeof additionalAccessName === 'string') {

                let array = additionalAccessName.split(",").map(item => item.trim());
                array = array.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
                //console.log(array); 

                additionalAccessName = array;
            } else if (Array.isArray(additionalAccessName)) {
                console.log(additionalAccessName)
                additionalAccessName = additionalAccessName.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
            } else if (typeof additionalAccessName === 'object') {

                additionalAccessName = additionalAccessName;
            }
            let formattedtrainingPeriodStart = trainingStartDate ? formatDate(trainingStartDate) : '';
            let formattedtrainingPeriodEnd = trainingEndDate ? formatDate(trainingEndDate) : '';
            let trainingMaterials;
            if (selectedMaterials && selectedMaterials.length > 0) {
                trainingMaterials = selectedMaterials.map(option => ({
                    training_id: option.trainID,
                    training_name: option.label
                }));
            } else {
                trainingMaterials = [];
            }


            const pcEquipmentPayload = Object.fromEntries(
                Object.keys(pcEquipment).map(key => [key, !!pcEquipment[key]])
            );

            const mobileAccessPayload = Object.fromEntries(
                Object.keys(mobileAccess).map(key => [key, !!mobileAccess[key]])
            );

            const softwareAccessPayload = Object.fromEntries(
                Object.keys(softwareAccess).map(key => [key, !!softwareAccess[key]])
            );


            const start_date = formData.start_date ? formatDate(formData.start_date) : '';
            const laptop_date = formData.laptop_date ? formatDate(formData.laptop_date) : '';
            const requestData = {
                employee_id: employeeId,
                classifications: {
                    ...formData,
                    start_date: start_date,
                    laptop_date: laptop_date,
                    training_material: trainingMaterials,
                    reporting_manager: selectedManager ? selectedManager.value : '',
                    status: "Pending",
                    created_by: admin,
                    pc_equipment: pcEquipmentPayload,
                    mobile_access: mobileAccessPayload,
                    software_access: softwareAccessPayload,
                    special_software_name: specialSoftwareName,
                    additional_access_name:additionalAccessName
                }
            };
            const responspc_equipmente = await OnboadingClassificationEmployeeDetails(url, token, employeeId, requestData);
            setRefreshEmployeeInfo(prevState => !prevState);
            toast.success(responspc_equipmente.msg);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    const hanldeSubmit = async (event) => {
        event.preventDefault();
        if ((formData.training_period_start && !formData.training_period_end) ||
            (!formData.training_period_start && formData.training_period_end)) {
            toast.error('Please fill both start date and end date.');
            return;
        }
        if (formData.training_period_start && formData.training_period_end) {
            const startDate = formatDate(formData.training_period_start);
            const endDate = formatDate(formData.training_period_end);
            if (startDate > endDate) {
                toast.error('Start date should be before the end date.');
                return;
            }
        }
        if (formData.special_software_needs && !formData.special_software_name) {
            toast.error('Please provide special software needs.');
            return;
        }

        if (formData.additional_access && !formData.additional_access_name) {
            toast.error('Please provide additional access.');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }

        if (formData.network_calling && !formData.country) {
            toast.error('Please Provide Which Country!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-classification';

            let specialSoftwareName = formData.special_software_name;
            let additionalAccessName =formData.additional_access_name
            if (typeof specialSoftwareName === 'string') {

                let array = specialSoftwareName.split(",").map(item => item.trim());
                array = array.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
                //console.log(array); 

                specialSoftwareName = array;
            } else if (Array.isArray(specialSoftwareName)) {
                console.log(specialSoftwareName)
                specialSoftwareName = specialSoftwareName.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
            } else if (typeof specialSoftwareName === 'object') {

                specialSoftwareName = specialSoftwareName;
            }
            
            if (typeof additionalAccessName === 'string') {

                let array = additionalAccessName.split(",").map(item => item.trim());
                array = array.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
                //console.log(array); 

                additionalAccessName = array;
            } else if (Array.isArray(additionalAccessName)) {
                console.log(additionalAccessName)
                additionalAccessName = additionalAccessName.reduce((acc, software) => {
                    acc[software] = true;
                    return acc;
                }, {});
            } else if (typeof additionalAccessName === 'object') {

                additionalAccessName = additionalAccessName;
            }
            let formattedtrainingPeriodStart = trainingStartDate ? formatDate(trainingStartDate) : '';
            let formattedtrainingPeriodEnd = trainingEndDate ? formatDate(trainingEndDate) : '';
            let trainingMaterials;
            if (selectedMaterials && selectedMaterials.length > 0) {
                trainingMaterials = selectedMaterials.map(option => ({
                    training_id: option.trainID,
                    training_name: option.label
                }));
            } else {
                trainingMaterials = [];
            }
            const pcEquipmentPayload = Object.fromEntries(
                Object.keys(pcEquipment).map(key => [key, !!pcEquipment[key]])
            );

            const mobileAccessPayload = Object.fromEntries(
                Object.keys(mobileAccess).map(key => [key, !!mobileAccess[key]])
            );

            const softwareAccessPayload = Object.fromEntries(
                Object.keys(softwareAccess).map(key => [key, !!softwareAccess[key]])
            );


            const start_date = formData.start_date ? formatDate(formData.start_date) : '';
            const laptop_date = formData.laptop_date ? formatDate(formData.laptop_date) : '';
            const requestData = {
                employee_id: employeeId,
                classifications: {
                    ...formData,
                    start_date: start_date,
                    laptop_date: laptop_date,
                    training_material: trainingMaterials,
                    reporting_manager: selectedManager ? selectedManager.value : '',
                    status: "Submitted",
                    created_by: admin,
                    pc_equipment: pcEquipmentPayload,
                    mobile_access: mobileAccessPayload,
                    software_access: softwareAccessPayload,
                    special_software_name: specialSoftwareName,
                    additional_access_name:additionalAccessName

                }
            };
            const responspc_equipmente = await OnboadingClassificationEmployeeDetails(url, token, employeeId, requestData);
            setRefreshEmployeeInfo(prevState => !prevState);
            toast.success(responspc_equipmente.msg);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handlepcCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setPCEquipment(prev => ({
            ...prev,
            [name]: checked
        }));
    };
    const handleSoftwareCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSoftwareAccess(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const handleMobileCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setMobileAccess(prev => ({
            ...prev,
            [name]: checked
        }));
    };
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseOver = () => {
        setShowTooltip(prevState => !prevState);
        // setShowTooltip(true);
    };

    // Handle mouse leave
    const handleMouseOut = () => {
        setShowTooltip(false);
    };

    const handleSelectChange1 = (selectedOption) => {
        setselectedManager(selectedOption);
    };

    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}>

                </div>

                <EmployeeInfo token={token} employeeId={employeeId} refreshEmployeeInfo={refreshEmployeeInfo} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={`${styles.fieldset} ${buttonsDisabled ? styles['fieldset-disabled'] : ''}`}>
                            <legend style={{ paddingBottom: "20px" }}>Employee Classification</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>

                                            <li><strong>Employment Type:</strong> <span>
                                                <SelectField
                                                    id="employment_type"
                                                    name="employment_type"
                                                    className={`${styles.select} ${styles.input}`}
                                                    options={typeOptions}
                                                    value={formData.employment_type}
                                                    onChange={handleChange}
                                                />
                                            </span></li>
                                            <li><strong>Title:<i className={styles.red}>*</i></strong>
                                                <span>
                                                    <input type="text" className={styles.input} value={formData.title} name="title" disabled />
                                                </span>
                                            </li>
                                            <li><strong>Start Date:</strong> <span>
                                                <DatePicker
                                                    selected={formData.start_date || ''}
                                                    onChange={handleStartDateChange}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className={styles.input}
                                                    id="start_date"
                                                    name="start_date"
                                                    autoComplete="off"
                                                    required
                                                    timeZone="UTC"
                                                    disabled


                                                />
                                            </span></li>
                                            <li><strong>Laptop Date:</strong> <span>
                                                <DatePicker
                                                    selected={formData.laptop_date || ''}
                                                    onChange={handleLaptopDateChange}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className={styles.input}
                                                    id="laptop_date"
                                                    name="laptop_date"
                                                    autoComplete="off"
                                                    required
                                                    timeZone="UTC"



                                                />
                                            </span></li>

                                            <li><strong>Role Type:</strong>
                                                <span>
                                                    <input type="text" className={styles.input} name="role_type" value={formData.role_type} onChange={handleChange} />
                                                </span>
                                            </li>
                                            <li><strong>Proposed Consulting Project:</strong> <span>
                                                <input type="text" className={styles.input} name="proposed_consulting_project" value={formData.proposed_consulting_project} onChange={handleChange} />
                                            </span></li>
                                            <li><strong>Location:</strong> <span>
                                                <SelectField
                                                    id="location"
                                                    name="location"
                                                    className={`${styles.select} ${styles.input}`}
                                                    options={locations}
                                                    onChange={handleChange}
                                                    value={formData.location}
                                                />
                                            </span></li>
                                            <li>
                                                <strong>Reporting Manager:</strong>
                                                <span>
                                                    <Select
                                                        value={selectedManager}
                                                        onChange={handleSelectChange1}
                                                        options={managerOptions}
                                                        placeholder="Select Manager"
                                                        name="reporting_manager"
                                                    />
                                                </span>
                                            </li>

                                            <li><strong>Special Software Needs:</strong> <span>
                                                <input
                                                    type="checkbox"
                                                    id="special_software_need"
                                                    name="special_software_needs"
                                                    onChange={handleAdditionalCheckboxChange}
                                                    checked={formData.special_software_needs}

                                                />
                                                <label htmlFor="special_software_need"></label>
                                                {formData.special_software_needs && (
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        name="special_software_name"
                                                        value={typeof formData.special_software_name === 'object'
                                                            ? Object.keys(formData.special_software_name).join(', ')
                                                            : formData.special_software_name
                                                        }
                                                        onChange={handleChange}
                                                        placeholder='Software name'
                                                    />
                                                )}

                                            </span></li>

                                            <li>

                                                <strong>Additional Access:</strong> <span>

                                                    <input
                                                        type="checkbox"
                                                        id="additional_access"
                                                        name="additional_access"
                                                        onChange={handleAccessCheckboxChange}
                                                        checked={formData.additional_access}

                                                    />
                                                    <label htmlFor="additional_access"></label>
                                                    {formData.additional_access && (
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            name="additional_access_name"
                                                            value={typeof formData.additional_access_name === 'object'
                                                                ? Object.keys(formData.additional_access_name).join(', ')
                                                                : formData.additional_access_name
                                                            }
                                                            onChange={handleChange}
                                                            placeholder='Additional access'
                                                        />
                                                    )}


                                                </span></li>






                                            <li><strong>Strategy Meeting:<i className="required"></i></strong> <span>
                                                <input type="checkbox" id="Strategy" name="strategy_meetings" value="Strategy meeting" checked={formData.strategy_meetings}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="Strategy"></label>
                                            </span></li>
                                            <li><strong>Operation:<i className="required"></i></strong> <span>
                                                <input type="checkbox" id="Operation" name="operation" value="Operation" checked={formData.operation}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="Operation"></label>
                                            </span></li>
                                            <li>
                                                <strong>Network Calling:</strong>
                                                <span className={styles.calling}>
                                                    <input
                                                        type="checkbox"
                                                        id="networkCalling"
                                                        name="network_calling"
                                                        checked={formData.network_calling}
                                                        onChange={handleCheckboxChangeIndia}
                                                    />
                                                    <label htmlFor="networkCalling">Toggle</label>
                                                </span>

                                            </li>
                                            {formData.network_calling && (<li><strong>Which Country :</strong> <span><input type="text" className={styles.input} name="country" value={formData.country} onChange={handleChange} /></span></li>)}
                                        </ul>
                                        <ul className={styles['two-col']}>

                                            <li><strong>Training Material:<i className="required"></i></strong> <span>
                                                <Select
                                                    options={materialOption}
                                                    onChange={handleSelectChange}
                                                    isClearable={materialOption.some((v) => !v.isFixed)}
                                                    isMulti
                                                    value={selectedMaterials}
                                                    name="training_material"
                                                />

                                            </span></li>
                                            <li><strong>Training Period:<i className="required"></i></strong></li>
                                            <li>
                                                <strong>Start Date:</strong>
                                                <span>
                                                    <DatePicker
                                                        selected={formData.training_period_start}
                                                        onChange={(date) => handleTrainingStartDateChange(date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className={styles.input}
                                                        id="training_period_start"
                                                        name="training_period_start"
                                                        autoComplete="off"
                                                        required
                                                        timeZone="UTC"
                                                    />
                                                </span>
                                            </li>
                                            <li>
                                                <strong>End Date:</strong>
                                                <span>
                                                    <DatePicker
                                                        selected={formData.training_period_end}
                                                        onChange={(date) => handleTrainingEndDateChange(date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className={styles.input}
                                                        id="training_period_end"
                                                        name="training_period_end"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </span>
                                                {error && <span style={{ color: 'red' }}>{error}</span>}
                                            </li>
                                            <li><strong>Training Days:<i className="required"></i></strong>
                                                <span>
                                                    <input type="number" id="trainingDay " name="training_period_days" className={styles.input} value={formData.training_period_days} onChange={handleChange} disabled />


                                                </span></li>

                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>All Hands:</strong>
                                                <span>
                                                    <input type="checkbox" id="allHand" name="all_hands" value="yes" checked={formData.all_hands}
                                                        onChange={handleCheckboxChange} />
                                                    <label htmlFor="allHand"></label>
                                                </span>
                                            </li>

                                            <li>
                                                <strong>All Heads:</strong>
                                                <span>
                                                    <input type="checkbox" id="allHead" name="all_heads" value="yes" checked={formData.all_heads}
                                                        onChange={handleCheckboxChange} />
                                                    <label htmlFor="allHead"></label>
                                                </span>
                                            </li>
                                            <li>
                                                {Object.keys(pcEquipment).length > 0 && (
                                                    <strong>PC Equipments:</strong>
                                                )}
                                                <span>
                                                    {Object.keys(pcEquipment).map((key) => (
                                                        <React.Fragment key={key}>
                                                            <input
                                                                type="checkbox"
                                                                id={key}
                                                                name={key}
                                                                checked={!!pcEquipment[key]}
                                                                onChange={handlepcCheckboxChange}
                                                            />
                                                            <label htmlFor={key}>
                                                                {key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}
                                                            </label>
                                                        </React.Fragment>
                                                    ))}
                                                </span>
                                            </li>


                                            <li>


                                                {Object.keys(softwareAccess).length > 0 && (
                                                    <strong>
                                                        Software Access:
                                                        <span
                                                            data-tooltip-id="software-tooltip"
                                                            style={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                width: '11px',
                                                                height: '11px',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'blue',
                                                                color: 'white',
                                                                fontSize: '11px',
                                                                cursor: 'pointer',
                                                                textAlign: 'center',
                                                                marginTop: '7px'
                                                            }}>

                                                            &#8505;
                                                        </span>
                                                    </strong>
                                                )}


                                                <span>
                                                    {Object.keys(softwareAccess).map((key) => (
                                                        <React.Fragment key={key}>
                                                            <input
                                                                type="checkbox"
                                                                id={key}
                                                                name={key}
                                                                checked={!!softwareAccess[key]}
                                                                onChange={handleSoftwareCheckboxChange}
                                                            />
                                                            <label htmlFor={key}>
                                                                {key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}
                                                            </label>
                                                        </React.Fragment>
                                                    ))}

                                                    {/* Tooltip trigger */}


                                                    {/* Tooltip content */}
                                                    <Tooltip id="software-tooltip" place="right" effect="solid">
                                                        <div>
                                                            <ul>
                                                                <li><strong>Software Includes:</strong></li>
                                                                <li><strong>Office 365:</strong> Word, Excel, PowerPoint, Teams, SharePoint, Outlook, other office apps</li>
                                                                <li><strong>AutoCad AEC:</strong> AutoCad, 3dsMax, Revit</li>
                                                                <li><strong>AutoCad LT:</strong> AutoCad LT</li>
                                                                <li><strong>Adobe Creative Suite:</strong> Photoshop, Premiere, Illustrator, After Effects</li>
                                                                <li><strong>Vray:</strong> Vray</li>
                                                                <li><strong>BlueBeam:</strong> BlueBeam</li>
                                                                <li><strong>Atlassian Cloud:</strong> Jira</li>
                                                                <li><strong>Hubspot:</strong> Hubspot CRM</li>
                                                                <li><strong>Dropbox:</strong> Dropbox Business</li>
                                                                <li><strong>Google Cloud:</strong> GSuite</li>
                                                                <li><strong>Roboform:</strong> Roboform</li>
                                                                <li><strong>Zoho:</strong> Accounts, Recruit, Payroll</li>

                                                            </ul>
                                                        </div>
                                                    </Tooltip>
                                                </span>

                                            </li>
                                            <li>

                                                {Object.keys(mobileAccess).length > 0 && (
                                                    <strong>Mobile Access:</strong>
                                                )}

                                                <span>
                                                    {Object.keys(mobileAccess).map((key) => (
                                                        <React.Fragment key={key}>
                                                            <input
                                                                type="checkbox"
                                                                id={key}
                                                                name={key}
                                                                checked={!!mobileAccess[key]}
                                                                onChange={handleMobileCheckboxChange}
                                                            />
                                                            <label htmlFor={key}>
                                                                {key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}
                                                            </label>
                                                        </React.Fragment>
                                                    ))}
                                                </span>
                                            </li>




                                        </ul>
                                    </div>
                                </div>
                                {rejectComment && (
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            <li><strong>IT Reject Comment:</strong> <span><textarea name="it_form_rejected_comment" className={styles.input} value={rejectComment} onChange={handleChange} ></textarea></span></li>

                                        </ul>
                                    </div>

                                )}
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange} ></textarea></span></li>

                                    </ul>
                                </div>

                                {/* Button block */}
                                <div className={styles['button-block']}>

                                    {!buttonsDisabled && buttonTabs.includes(tabName) && (
                                        <input type="submit" value="Save" className={styles.button} onClick={hanldeSaveInfo} />
                                    )}


                                    {!buttonsDisabled && buttonTabs.includes(tabName) && (
                                        <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} />
                                    )}
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                        <div className={styles.newsloader}></div></div>
                </div>}
            </div>

        </div>
    );
};

export default Classification;
