
import React, { useEffect, useState } from 'react';
import styles from '../Boading.module.scss';

import DatePicker from "react-datepicker";
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import ITLog from '../../api/ITLog';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
const ITHistory = ({ presentData, authorized, token, employeeId, selectedLog,setSelectedLog }) => {
    const [formData, setFormData] = useState(presentData);
    const [fetchDataState, setFetchDataState] = useState(null);
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const closePopup = () => {
        setSelectedLog('');
    }

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            if (authorized) {
                try {
                    const params = {
                        employee_id: employeeId,
                        log_date: selectedLog,
                    };
                    const employeeData = await ITLog(token, params);
                    if (employeeData.status === 200) {
                        setFetchDataState(employeeData.data); 
                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                }
            }
        };

        fetchData();
    }, [authorized, token, employeeId, selectedLog]);

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    function extractDate(timestamp) {
        const date = new Date(timestamp);
        if (isNaN(date.getTime())) {
          console.error("Invalid timestamp:", timestamp);
          return null;  
        }
        return date.toISOString().split('T')[0];
      }
    const generateLabel = (key) => {
        return key.replace(/_/g, " ").toUpperCase();
    };
    
    const renderFields = (data, parentKey = '') => {
        return Object.keys(data).map((key) => {
            const value = data[key];
            const fieldName = parentKey ? `${parentKey}.${key}` : key;

            if (typeof value === "boolean" || value === "") {
                return (
                    <li key={fieldName}>
                        <span>
                            <input
                                type="checkbox"
                                id={fieldName}
                                name={fieldName}
                                checked={value === true}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor={fieldName}>
                                {capitalizeFirstLetter(key.replace(/_/g, " "))}
                            </label>
                        </span>
                    </li>
                );
            }
            if (typeof value === "string" || value === null) {
                if (key === "comment") {
                    return (
                        <div key={fieldName} className={styles['left-space']}>
                            
                            <textarea
                                id={fieldName}
                                name={fieldName}
                                value={value || ""}
                                onChange={handleTextChange}
                                className={styles.input}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div key={fieldName} className={styles['left-space']}>
                            
                            <input
                                type="text"
                                id={fieldName}
                                name={fieldName}
                                value={value || ""}
                                onChange={handleTextChange}
                                className={styles.input}
                                
                            />
                        </div>
                    );
                }
            }

            

            if (typeof value === "object" && value !== null) {
                return (
                    <div key={fieldName}>
                        <strong>{generateLabel(key)}:</strong>
                        <ul>
                            {renderFields(value, key)}
                        </ul>
                    </div>
                );
            }

            return null;
        });
    };

    const renderSection = (sectionName, sectionData,className = "") => {
        if (!sectionData) return null;  

        return (
            <div className={`${styles[className]}`}>
                <strong>{sectionName}:</strong>
                <ul>
                    {renderFields(sectionData)}
                </ul>
            </div>
        );
    };

    return (
        <div className={styles.employee} style={{ maxHeight: '600px', overflowY: 'auto' , width:'900px'}}>
            <button className={styles.closeHistoryButton} onClick={closePopup}>X</button>  {/* Close button */}
            <div className={styles.grid}>
                <div  className={`${styles.col6} ${styles.highlightedSection}`}>
                    <div>
                        <fieldset className={`${styles.fieldset} ${styles['fieldset-disabled']}`}>
                            <div className={styles.grid}>
                               
                            <div >
                                <h5 style={{padding:'20px', fontWeight:'600'}}>Current IT Data</h5>
                                    {renderSection("Software Access", formData.software_access)}
                                    {renderSection("Mobile Access", formData.mobile_access)}
                                    {renderSection("PC Equipment", formData.pc_equipment)}
                                    
                                    {renderSection("Special Software Name", formData.special_software_name)}
                                    
                                    {renderSection("Additional Access Name", formData.additional_access_name)}
                                    {renderSection("Network Calling", { network_calling: formData.network_calling })}
                                    {renderSection("Security Code", { security_code: formData.security_code })}
                                    {renderSection("Laptop Ready for Shipment", { laptop_ready_for_shipment: formData.laptop_ready_for_shipment })}
                                    {renderSection("Security Information Sent", { security_information_sent: formData.security_information_sent })}
                                    {renderSection("Country", { country: formData.country })}
                                    {renderSection("Created By", { created_by: formData.created_by })}
                                    {renderSection("Comment", { comment: formData.comment })}
                                    
                                </div>
                                
                                
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className={styles.col5}>
                    <div>
                        <fieldset className={`${styles.fieldset} ${styles['fieldset-disabled']}`}>
                            <div className={styles.grid}>
                               
                            <h5 style={{padding:'20px', fontWeight:'600'}}> {selectedLog} IT Data</h5>
                                <div >
                                    <div className={styles['left-space']}>
                                    {fetchDataState && renderSection("Software Access", fetchDataState.software_access, "highlighted")}
                                    {fetchDataState && renderSection("Mobile Access", fetchDataState.mobile_access,"highlighted")}
                                    {fetchDataState && renderSection("PC Equipment", fetchDataState.pc_equipment,"highlighted")}
                                   
                                    {fetchDataState && renderSection("Special Software Name", fetchDataState.special_software_name,"highlighted")}
                                    
                                    {fetchDataState && renderSection("Additional Access Name", fetchDataState.additional_access_name,"highlighted")}
                                    {fetchDataState &&renderSection("Network Calling", { network_calling: fetchDataState.network_calling} ,"highlighted")}
                                    {fetchDataState &&renderSection("Security Code", { security_code: fetchDataState.security_code } ,"highlighted")}
                                    {fetchDataState &&renderSection("Laptop Ready for Shipment", { laptop_ready_for_shipment: fetchDataState.laptop_ready_for_shipment } ,"highlighted")}
                                    {fetchDataState &&renderSection("Security Information Sent", { security_information_sent: fetchDataState.security_information_sent } ,"highlighted")}
                                    {fetchDataState &&renderSection("Country", { country: fetchDataState.country } , "highlighted")}
                                    {fetchDataState &&renderSection("Created By", { created_by: fetchDataState.created_by } , "highlighted")}
                                    {fetchDataState &&renderSection("Comment", { comment: fetchDataState.comment },  "highlighted")}


                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ITHistory;
